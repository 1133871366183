import React, { Component } from "react";
import { Button, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import strings from "../locale/strings.json";
import { formatBytes } from "../util/util";
import { getUploadedFileInfo, uploadFile } from "../api/api";
import withRouter from "../util/withRouter";
import { getAccessToken } from "../api/jwt";
import axios from "axios";
import fileDownload from "js-file-download";

class StepAmbHochladen extends Component {
  state = { selectedFiles: [] };

  validate = () => {
    const { selectedFiles } = this.state;
    const { setCurrentStepValid } = this.props;
    setCurrentStepValid(selectedFiles.length > 0);
  };

  handleFileDownload = async (url, filename) => {
    const unmountLoadingMsg = message.loading({
      content: "Downloading attachment ...",
    });

    const token = getAccessToken();

    try {
      const data = (
        await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
          responseType: "blob",
        })
      ).data;

      if (data) {
        fileDownload(data, filename);
      }
    } catch {
      message.error("You don't have access to that file.");
    } finally {
      unmountLoadingMsg();
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { selectedFiles } = this.state;
    const {
      isActive,
      formData,
      router: {
        params: { formId },
      },
    } = this.props;
    const { isActive: wasActive } = prevProps;

    if (isActive && !wasActive) {
      this.validate();

      if (
        selectedFiles.length === 0 &&
        formData.termsOfEngagement &&
        formData.termsOfEngagement.fileUuid
      ) {
        const unmountLoadingMessageCb = message.loading({
          content: "Loading file info ...",
        });

        const response = getUploadedFileInfo(
          formId,
          formData.termsOfEngagement.fileUuid
        );

        if (response != null) {
          const { fileInfo } = (await response).data;
          unmountLoadingMessageCb();

          if (fileInfo) {
            this.setState(
              {
                selectedFiles: [
                  { name: fileInfo.originalFilename, size: fileInfo.filesize },
                ],
              },
              this.validate
            );
          }
        } else {
          unmountLoadingMessageCb();
        }
      }
    }
  };

  render() {
    const { selectedFiles } = this.state;
    const {
      formData,
      currentLang,
      router: { params },
    } = this.props;

    const uploadProps = {
      accept: ".pdf,.jpg,.jpeg",
      className: "uploader",
      name: "file",
      multiple: false,
      action: "",
      beforeUpload: async (file) => {
        const unmountLoadingMessageCb = message.loading({
          content: "Uploading the document ...",
        });

        const { onChangeFormData, setCurrentStepValid } = this.props;

        const response = uploadFile(params.formId, file);

        if (response != null) {
          const { success, uuid } = (await response).data;

          unmountLoadingMessageCb();

          if (success && uuid) {
            message.success("File was uploaded successfully.");
            this.setState({ selectedFiles: [file] });
            setCurrentStepValid(true);
            onChangeFormData("termsOfEngagement", { fileUuid: uuid });
          } else {
            message.error("There was an error uploading the file.");
            setSelectedFiles({ selectedFiles: [] });
            setCurrentStepValid(false);
            onChangeFormData("termsOfEngagement", null);
          }
        } else {
          unmountLoadingMessageCb();
          setSelectedFiles({ selectedFiles: [] });
          message.error("Error uploading the file.");
          onChangeFormData("termsOfEngagement", null);
        }
      },
      showUploadList: false,
      style: selectedFiles.length === 0 ? null : { display: "none" },
    };

    return (
      <>
        <h2>{strings[currentLang].STEP_UPLOAD_TERMS_OF_ENGAGEMENT}</h2>

        <Upload.Dragger {...uploadProps} fileList={selectedFiles}>
          <InboxOutlined className="icon" />
          <p className="upload-text">
            {strings[currentLang].CLICK_OR_DRAG_TO_UPLOAD}
          </p>
          <p className="upload-hint">
            {strings[currentLang].CHOOSE_MULTIPLE_FILES}
          </p>
        </Upload.Dragger>

        <div className={selectedFiles.length === 0 ? "fade-out" : "fade-in"}>
          {selectedFiles.length > 0 && (
            <>
              {selectedFiles[0].name} (
              <i>{formatBytes(selectedFiles[0].size)}</i>){" "}
              <Button
                type="link"
                onClick={() => {
                  this.handleFileDownload(
                    `${process.env.PUBLIC_URL}/api/kycForm/previewUploadedDocument/${params.formId}/${formData.termsOfEngagement.fileUuid}`,
                    selectedFiles[0].name
                  );
                }}
              >
                {strings[currentLang].PREVIEW}
              </Button>
              <Button
                type="link"
                danger
                onClick={() => {
                  this.setState({ selectedFiles: [] });
                  setCurrentStepValid(false);
                }}
              >
                {strings[currentLang].REMOVE}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}

StepAmbHochladen.propTypes = {
  currentLang: PropTypes.oneOf(["de", "en"]),
  formData: PropTypes.any,
  isActive: PropTypes.bool,
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  router: PropTypes.any,
};

export default withRouter(StepAmbHochladen);
