import PropTypes from "prop-types";
import { InboxOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import React, { Component } from "react";
import strings from "../../locale/strings.json";
import { formatBytes } from "../../util/util";
import { getAccessToken } from "../../api/jwt";
import axios from "axios";
import fileDownload from "js-file-download";
import { getUploadedFileInfo, uploadFile } from "../../api/api";
import withRouter from "../../util/withRouter";

class NatDokumentUpload extends Component {
  state = { selectedFiles: [] };

  validate = () => {
    const { selectedFiles } = this.state;
    const { setCurrentStepValid } = this.props;

    setCurrentStepValid(selectedFiles && selectedFiles.length > 0);
  };

  handleFileDownload = async (url, filename) => {
    const unmountLoadingMsg = message.loading({
      content: "Downloading attachment ...",
    });

    const token = getAccessToken();

    try {
      const data = (
        await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
          responseType: "blob",
        })
      ).data;

      if (data) {
        fileDownload(data, filename);
      }
    } catch {
      message.error("You don't have access to that file.");
    } finally {
      unmountLoadingMsg();
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { selectedFiles } = this.state;
    const {
      isActive,
      formData,
      router: {
        params: { formId },
      },
    } = this.props;
    const { isActive: wasActive } = prevProps;

    if (isActive && !wasActive) {
      this.validate();

      if (
        selectedFiles.length === 0 &&
        formData.clientData &&
        formData.clientData.photoId &&
        formData.clientData.photoId.fileUuid
      ) {
        const unmountLoadingMessageCb = message.loading({
          content: "Loading file info ...",
        });

        const response = getUploadedFileInfo(
          formId,
          formData.clientData.photoId.fileUuid
        );

        if (response != null) {
          const { fileInfo } = (await response).data;
          unmountLoadingMessageCb();

          if (fileInfo) {
            this.setState(
              {
                selectedFiles: [
                  { name: fileInfo.originalFilename, size: fileInfo.filesize },
                ],
              },
              this.validate
            );
          }
        } else {
          unmountLoadingMessageCb();
        }
      }
    }
  };

  render() {
    const { selectedFiles } = this.state;
    const {
      currentLang,
      formData,
      onChangeFormData,
      router: { params },
    } = this.props;

    const { clientData } = formData;
    let firstName = "";
    let lastName = "";

    if (clientData) {
      firstName = clientData.firstName;
      lastName = clientData.lastName;
    }

    const uploadProps = {
      accept: ".pdf,.jpg,.jpeg",
      className: "uploader",
      name: "file",
      multiple: false,
      action: "",
      beforeUpload: async (file) => {
        if (file && file.size && file.size < 15 * 1024 * 1024) {
          this.setState({ loading: true });

          const unmountLoadingMessageCb = message.loading({
            content: strings[currentLang].UPLOADING_DOCUMENT,
          });

          const { onChangeFormData, setCurrentStepValid } = this.props;

          try {
            const response = await uploadFile(params.formId, file);

            const status = response.status;

            if (status !== 200) {
              message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              unmountLoadingMessageCb();
            } else {
              const { success, uuid } = response.data;

              unmountLoadingMessageCb();

              if (success && uuid) {
                message.success(
                  strings[currentLang].SUCCESS_UPLOADING_DOCUMENT
                );
                this.setState({ selectedFiles: [file] });
                setCurrentStepValid(true);
                onChangeFormData("clientData", {
                  ...formData.clientData,
                  photoId: { fileUuid: uuid },
                });
              } else {
                message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
                this.setState({ selectedFiles: [] });
                setCurrentStepValid(false);
                onChangeFormData("clientData", {
                  ...formData.clientData,
                  photoId: null,
                });
              }
            }
          } catch (err) {
            console.log("err", err);
            unmountLoadingMessageCb();
            this.setState({ selectedFiles: [] });
            message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
            onChangeFormData("clientData", {
              ...formData.clientData,
              photoId: null,
            });
          }
        } else {
          message.error(strings[currentLang].UPLOADING_DOCUMENT_TOO_BIG);
        }
      },
      showUploadList: false,
      style: selectedFiles.length === 0 ? null : { display: "none" },
    };

    return (
      <>
        <h2>{strings[currentLang].nat.STEP_UPLOAD_OFFICIAL_DOCUMENT}</h2>
        {firstName && lastName && (
          <ul>
            <li>
              <span style={{ fontSize: "14pt", fontWeight: "lighter" }}>
                {firstName} {lastName}
              </span>
            </li>
          </ul>
        )}
        {selectedFiles.length === 0 && (
          <Upload.Dragger {...uploadProps} fileList={selectedFiles}>
            <InboxOutlined className="icon" />
            <p className="upload-text">
              {strings[currentLang].CLICK_OR_DRAG_TO_UPLOAD}
            </p>
            <p className="upload-hint">
              {strings[currentLang].CHOOSE_MULTIPLE_FILES}
            </p>
          </Upload.Dragger>
        )}
        <div className={selectedFiles.length === 0 ? "fade-out" : "fade-in"}>
          {selectedFiles.length > 0 && (
            <>
              {selectedFiles[0].name} (
              <i>{formatBytes(selectedFiles[0].size)}</i>){" "}
              <Button
                type="link"
                onClick={() => {
                  this.handleFileDownload(
                    `${process.env.PUBLIC_URL}/api/kycForm/previewUploadedDocument/${params.formId}/${formData.clientData.photoId.fileUuid}`,
                    selectedFiles[0].name
                  );
                }}
              >
                {strings[currentLang].PREVIEW}
              </Button>
              <Button
                type="link"
                danger
                onClick={() => {
                  this.setState({ selectedFiles: [] }, this.validate);
                }}
              >
                {strings[currentLang].REMOVE}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }
}

NatDokumentUpload.propTypes = {
  isActive: PropTypes.bool,
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    clientData: PropTypes.shape({
      firstName: PropTypes.any,
      lastName: PropTypes.any,
      photoId: PropTypes.any,
    }),
  }),
  setCurrentStepValid: PropTypes.func,
  onChangeFormData: PropTypes.func,
  router: PropTypes.any,
};

export default withRouter(NatDokumentUpload);
