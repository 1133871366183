import React, { Component } from "react";
import { Row, Segmented, Steps } from "antd";
import ReactMarkdown from "react-markdown";
import "./styles.css";
import strings from "./locale/strings.json";
import svlawLogo from "./svlaw-logo.png";
import { getAccessToken } from "./api/jwt";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import InformationOhneFormular from "./InformationOhneFormular";
import Authenticate from "./Authenticate";
import KycSteps from "./Steps";
import { AuthProvider, RequireAuth } from "./AuthProvider";
import { getCurrentLanguage, setCurrentLanguage } from "./util/util";
import StepFormSubmitted from "./steps/StepFormSubmitted";
import FormWiederAktivieren from "./FormWiederAktivieren";
import StepFormConfirmed from "./steps/StepFormConfirmed";

class App extends Component {
  state = {
    currentStep: 0,
    currentLang: getCurrentLanguage(),
    isAuthenticated: false,
    clientType: null,
    privacyFooter: null,
    allowStepNavigation: false,
  };

  onStepClick = (index) => {
    const { allowStepNavigation } = this.state;
    if (allowStepNavigation) {
      this.setState({ currentStep: index });
    }
  };

  componentDidMount = () => {
    const isAuthenticated = getAccessToken() != null;
    this.setState({ isAuthenticated });
  };

  render() {
    const { currentLang, currentStep, clientType, allowStepNavigation } =
      this.state;

    const CT = clientType;

    return (
      <AuthProvider>
        <div className="App">
          <div className="main-container">
            <div className="left">
              <div className="content">
                <h1 className="onboarding-header">SV.LAW CLIENT ONBOARDING</h1>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <InformationOhneFormular currentLang={currentLang} />
                      }
                    />
                    <Route
                      path="/form/:formId/auth"
                      element={<Authenticate currentLang={currentLang} />}
                    />
                    <Route
                      path="/form/:formId"
                      element={
                        <RequireAuth>
                          <KycSteps
                            currentStep={currentStep}
                            currentLang={currentLang}
                            onClientTypeChange={(ct) => {
                              this.setState({ clientType: ct });
                            }}
                            onLanguageChange={(lang) => {
                              this.setState({ currentLang: lang });
                              setCurrentLanguage(lang);
                            }}
                            onStepChange={(currentIndex) => {
                              this.setState({ currentStep: currentIndex });
                            }}
                            onReachedLastStep={() => {
                              this.setState({ allowStepNavigation: true });
                            }}
                            onSubmit={() => {
                              this.setState({ currentStep: -1 });
                            }}
                          />
                        </RequireAuth>
                      }
                    />
                    <Route path="/submitted" element={<Navigate to="/" />} />
                    <Route
                      path="/confirm/:formId"
                      element={
                        <RequireAuth>
                          <StepFormConfirmed currentLang={currentLang} />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/submitted/:formId"
                      element={
                        <StepFormSubmitted
                          currentLang={currentLang}
                          hideSteps={() => {
                            this.setState({ currentStep: -1 });
                          }}
                        />
                      }
                    />
                    <Route
                      path="/edit/:formId"
                      element={
                        <RequireAuth>
                          <FormWiederAktivieren />
                        </RequireAuth>
                      }
                    />
                  </Routes>
                </BrowserRouter>
              </div>
              <Row
                style={{ marginTop: "auto" }}
                className="privacy-footer-wrapper"
              >
                <ReactMarkdown className="privacy-footer">
                  {strings[currentLang].PRIVACY_FOOTER.replace(
                    "[AMB_DOCUMENT_URL]"
                  )}
                </ReactMarkdown>
              </Row>
            </div>
            <div className="right">
              <img
                src={svlawLogo}
                className="svlaw-logo"
                alt="STADLER VÖLKEL Logo"
              />
              {currentStep >= 0 && CT && CT === "nat" && (
                <div style={{ width: "100%" }} className="steps-nat">
                  <Steps
                    direction="vertical"
                    className="progress-steps"
                    current={currentStep}
                    progressDot
                  >
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_TYPE_OF_ENTITY}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].nat.STEP_TITLE_PERSONAL_INFORMATION
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_TAXATION}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_LEGAL_SERVICES}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_PEP}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].nat.STEP_TITLE_DOCUMENT_UPLOAD
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_FORM_EDITOR}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].nat.STEP_TITLE_TERMS_OF_ENGAGEMENT
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].nat.STEP_TITLE_SUMMARY}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                  </Steps>
                  {allowStepNavigation && (
                    <p
                      style={{
                        marginTop: "auto",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {strings[currentLang].CLICK_TO_SWITCH_BETWEEN_STEPS}
                    </p>
                  )}
                </div>
              )}
              {currentStep >= 0 && CT && CT === "jur" && (
                <div style={{ width: "100%" }} className="steps-jur">
                  <Steps
                    direction="vertical"
                    className="progress-steps"
                    current={currentStep}
                    progressDot
                  >
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_TYPE_OF_ENTITY}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_ENTITY_INFORMATION
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_TAXATION}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_LEGAL_SERVICES}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_MANAGING_DIRECTORS
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_PEP}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur
                          .STEP_TITLE_MANAGING_DIRECTORS_DOCUMENTS
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_BENEFICIAL_OWNERS
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_PEP}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur
                          .STEP_TITLE_BENEFICIAL_OWNERS_DOCUMENTS
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_CONTACT_INFORMATION
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_FORM_EDITOR}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_ADDITIONAL_DOCUMENTS
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={
                        strings[currentLang].jur.STEP_TITLE_TERMS_OF_ENGAGEMENT
                      }
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                    <Steps.Step
                      title={strings[currentLang].jur.STEP_TITLE_SUMMARY}
                      onStepClick={
                        allowStepNavigation ? this.onStepClick : null
                      }
                    />
                  </Steps>
                  {allowStepNavigation && (
                    <p
                      style={{
                        marginTop: "auto",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {strings[currentLang].CLICK_TO_SWITCH_BETWEEN_STEPS}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </AuthProvider>
    );
  }
}

export default App;
