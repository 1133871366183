import PropTypes from "prop-types";
import { Button, message, Upload } from "antd";
import React, { Component } from "react";
import strings from "../../locale/strings.json";
import { formatBytes } from "../../util/util";
import { getUploadedFileInfo, uploadFile } from "../../api/api";
import withRouter from "../../util/withRouter";
import _ from "lodash";
import axios from "axios";
import fileDownload from "js-file-download";
import { getAccessToken } from "../../api/jwt";

class JurGeschaeftsfuehrerDokumentUpload extends Component {
  state = { selectedFiles: {} };

  validate = () => {
    const { formData, setCurrentStepValid, isActive } = this.props;
    const { managingDirectors } = formData;

    if (isActive) {
      if (managingDirectors) {
        const { selectedFiles } = this.state;

        setCurrentStepValid(
          managingDirectors.filter((md) => selectedFiles[md.key] == null)
            .length === 0
        );
      } else {
        setCurrentStepValid(false);
      }
    }
  };

  loadFileInfoForKeys = async (keys) => {
    const unmountLoadingMsg = message.loading({
      content: "Loading file infos ...",
    });

    const {
      router: { params },
    } = this.props;

    for (let i = 0; i < Object.keys(keys).length; i++) {
      const key = Object.keys(keys)[i];
      const fileUuid = keys[key];

      const { success, fileInfo } = (
        await getUploadedFileInfo(params.formId, fileUuid)
      ).data;

      if (success && fileInfo) {
        this.setState((state) => ({
          selectedFiles: {
            ...state.selectedFiles,
            [key]: { name: fileInfo.originalFilename, size: fileInfo.filesize },
          },
        }));
      }
    }

    unmountLoadingMsg();
  };

  handleFileDownload = async (url, filename) => {
    const unmountLoadingMsg = message.loading({
      content: "Downloading attachment ...",
    });

    const token = getAccessToken();

    try {
      const data = (
        await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
          responseType: "blob",
        })
      ).data;

      if (data) {
        fileDownload(data, filename);
      }
    } catch {
      message.error("You don't have access to that file.");
    } finally {
      unmountLoadingMsg();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { selectedFiles } = this.state;
    const { isActive, formData } = this.props;
    const { isActive: wasActive } = prevProps;

    if (isActive && !wasActive) {
      const { managingDirectors } = formData;

      if (
        managingDirectors &&
        _.size(managingDirectors) > 0 &&
        _.size(selectedFiles) === 0
      ) {
        const keys = {};

        for (let i = 0; i < _.size(managingDirectors); i++) {
          if (
            managingDirectors[i].photoId &&
            managingDirectors[i].photoId.fileUuid
          ) {
            keys[managingDirectors[i].key] =
              managingDirectors[i].photoId.fileUuid;
          }
        }

        this.loadFileInfoForKeys(keys).then(this.validate);
      } else {
        this.validate();
      }
    }
  };

  render() {
    const { selectedFiles } = this.state;
    const { currentLang, formData, onChangeFormData } = this.props;

    const {
      router: { params },
    } = this.props;

    const { managingDirectors } = formData;

    const uploadProps = (md) => ({
      accept: ".jpg,.jpeg,.pdf",
      className: "uploader",
      name: "file",
      multiple: false,
      action: "",
      beforeUpload: async (file) => {
        if (file && file.size && file.size < 15 * 1024 * 1024) {
          this.setState({ loading: true });

          const unmountLoadingMessageCb = message.loading({
            content: strings[currentLang].UPLOADING_DOCUMENT,
          });

          try {
            const response = await uploadFile(params.formId, file);

            const status = response.status;

            if (status !== 200) {
              message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              unmountLoadingMessageCb();
            } else {
              const { success, uuid } = response.data;

              unmountLoadingMessageCb();

              if (success && uuid) {
                this.setState(
                  (s) => ({
                    selectedFiles: { ...s.selectedFiles, [md.key]: file },
                  }),
                  this.validate
                );

                message.success(
                  strings[currentLang].SUCCESS_UPLOADING_DOCUMENT
                );

                // das file zum Managing Director anfügen
                const idx = managingDirectors.findIndex(
                  (md2) => md2.key === md.key
                );
                managingDirectors[idx].photoId = { fileUuid: uuid };
                onChangeFormData("managingDirectors", managingDirectors);
              } else {
                message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              }
            }
          } catch {
            message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
            unmountLoadingMessageCb();
          }
        } else {
          message.error(strings[currentLang].UPLOADING_DOCUMENT_TOO_BIG);
        }
      },
      showUploadList: false,
    });

    return (
      <>
        <h2>
          {
            strings[currentLang].jur
              .STEP_UPLOAD_OFFICIAL_DOCUMENT_FOR_EACH_MANAGING_DIRECTOR
          }
        </h2>
        {managingDirectors && (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {managingDirectors.map((md, index) => (
              <li
                key={md.key}
                style={{
                  borderBottom:
                    index < managingDirectors.length - 1
                      ? "1px solid #dddddd"
                      : 0,
                  padding: "24px 0 32px 0",
                }}
              >
                <span style={{ fontSize: "14pt", fontWeight: "lighter" }}>
                  {md.firstName} {md.lastName}
                </span>
                {!selectedFiles[md.key] && (
                  <div>
                    <Upload.Dragger {...uploadProps(md)}>
                      <p className="upload-text">
                        {strings[currentLang].CLICK_OR_DRAG_TO_UPLOAD}
                      </p>
                      <p className="upload-hint">
                        {strings[currentLang].CHOOSE_MULTIPLE_FILES}
                      </p>
                    </Upload.Dragger>
                  </div>
                )}
                <div
                  className={!selectedFiles[md.key] ? "fade-out" : "fade-in"}
                >
                  {selectedFiles[md.key] && (
                    <>
                      {selectedFiles[md.key].name} (
                      <i>{formatBytes(selectedFiles[md.key].size)}</i>){" "}
                      <Button
                        type="link"
                        onClick={() => {
                          this.handleFileDownload(
                            `${process.env.PUBLIC_URL}/api/kycForm/previewUploadedDocument/${params.formId}/${md.photoId.fileUuid}`,
                            selectedFiles[md.key].name
                          );
                        }}
                      >
                        {strings[currentLang].PREVIEW}
                      </Button>
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          this.setState((s) => {
                            const { [md.key]: x, ...newSelectedFiles } =
                              s.selectedFiles;
                            return {
                              selectedFiles: newSelectedFiles,
                            };
                          }, this.validate);
                        }}
                      >
                        {strings[currentLang].REMOVE}
                      </Button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }
}

JurGeschaeftsfuehrerDokumentUpload.propTypes = {
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    managingDirectors: PropTypes.array,
  }),
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
  onChangeFormData: PropTypes.func,
  router: PropTypes.any,
};

export default withRouter(JurGeschaeftsfuehrerDokumentUpload);
