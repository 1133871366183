import React, { Component } from "react";
import { Alert, Checkbox, Descriptions, Input, Space } from "antd";
import PropTypes from "prop-types";
import countries from "i18n-iso-countries";
import strings from "../../locale/strings.json";

class JurZusammenfassung extends Component {
  state = { confirmed: false, additionalInformation: null };

  constructor(props) {
    super(props);

    this.textareaChangeTimeout = null;
  }

  validate = () => {
    const { confirmed } = this.state;
    const { setCurrentStepValid } = this.props;

    setCurrentStepValid(confirmed);
  };

  componentDidUpdate = (prevProps) => {
    const { isActive, formData } = this.props;
    const { isActive: wasActive } = prevProps;

    const { additionalInformation } = this.state;

    if (isActive && !wasActive) {
      if (
        additionalInformation == null &&
        formData &&
        formData.additionalInformation
      ) {
        this.setState({
          additionalInformation: formData.additionalInformation,
        });
      }
      this.validate();
    }
  };

  render() {
    const { confirmed, additionalInformation } = this.state;
    const { currentLang, formData, onChangeFormData } = this.props;

    return (
      <>
        <h2>
          {strings[currentLang].jur.PLEASE_CHECK_SUMMARY_OF_YOUR_DATA.replace(
            "[NAME_LEGAL_ENTITY]",
            formData.clientData && formData.clientData.nameLegalEntity
              ? formData.clientData.nameLegalEntity
              : ""
          )}
        </h2>
        <Space style={{ width: "100%" }} direction="vertical" size="large">
          <div>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={{
                width: "25%",
                fontWeight: "bold",
                verticalAlign: "top",
              }}
            >
              {formData.clientType === "jur" && formData.clientData && (
                <>
                  <Descriptions.Item
                    label={strings[currentLang].jur.NAME_LEGAL_ENTITY}
                  >
                    {formData.clientData.nameLegalEntity}
                  </Descriptions.Item>
                  <Descriptions.Item label={strings[currentLang].jur.ADDRESS}>
                    {formData.clientData.businessAddress}
                    <br />
                    {formData.clientData.postalCode} {formData.clientData.city}
                    <br />
                    {countries.getName(
                      formData.clientData.countryOfRegistration,
                      currentLang,
                      { select: "official" }
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={strings[currentLang].jur.REG_NO}>
                    {formData.clientData.registrationNumber}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].jur.VAT_NUMBER}
                  >
                    {formData.clientData.vatNumber
                      ? `${formData.clientData.countryOfRegistration} ${formData.clientData.vatNumber}`
                      : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].jur.EMAIL_ADDRESS}
                  >
                    {formData.clientData.invoiceEmail}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].jur.STEP_TITLE_LEGAL_SERVICES}
                  >
                    {formData.legalServices &&
                      formData.legalServices.map((ls) => <p key={ls}>{ls}</p>)}
                    {formData.otherLegalService && (
                      <p>
                        {strings[currentLang].jur.OTHER}:{" "}
                        {formData.otherLegalService}
                      </p>
                    )}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </div>

          <Input.TextArea
            rows={2}
            placeholder={strings[currentLang].jur.STEP_ADDITIONAL_REMARKS}
            onChange={(e) => {
              this.setState({ additionalInformation: e.target.value });
              if (this.textareaChangeTimeout)
                clearTimeout(this.textareaChangeTimeout);

              this.textareaChangeTimeout = setTimeout(() => {
                onChangeFormData("additionalInformation", e.target.value);
              }, 400);
            }}
            value={additionalInformation}
          />

          <Alert
            type="warning"
            description={
              <Checkbox
                checked={confirmed}
                onChange={(e) => {
                  this.setState({ confirmed: e.target.checked }, this.validate);
                }}
              >
                {strings[currentLang].jur.I_CONFIRM_PROVIDED_INFORMATION}
              </Checkbox>
            }
          />
        </Space>
      </>
    );
  }
}

JurZusammenfassung.propTypes = {
  formData: PropTypes.any,
  currentLang: PropTypes.any,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
  onChangeFormData: PropTypes.func,
};

export default JurZusammenfassung;
