import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, message, Table, Upload } from "antd";
import strings from "../../locale/strings.json";
import { getUploadedFileInfo, uploadFile } from "../../api/api";
import withRouter from "../../util/withRouter";
import { formatBytes } from "../../util/util";
import _ from "lodash";
import { getAccessToken } from "../../api/jwt";
import fileDownload from "js-file-download";
import axios from "axios";
import ReactMarkdown from "react-markdown";

class JurZusaetzlicheDokumenteUpload extends Component {
  state = { selectedFiles: {} };

  handleFileDownload = async (url, filename) => {
    const unmountLoadingMsg = message.loading({
      content: "Downloading attachment ...",
    });

    const token = getAccessToken();

    try {
      const data = (
        await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
          responseType: "blob",
        })
      ).data;

      if (data) {
        fileDownload(data, filename);
      }
    } catch {
      message.error("You don't have access to that file.");
    } finally {
      unmountLoadingMsg();
    }
  };

  loadFileInfos = async (docs) => {
    const unmountLoadingMsg = message.loading({
      content: "Loading file infos ...",
    });

    const {
      router: { params },
    } = this.props;

    for (let i = 0; i < Object.keys(docs).length; i++) {
      const name = Object.keys(docs)[i];
      const fileUuid = docs[name].fileUuid;

      const { success, fileInfo } = (
        await getUploadedFileInfo(params.formId, fileUuid)
      ).data;

      if (success && fileInfo) {
        this.setState((state) => ({
          selectedFiles: {
            ...state.selectedFiles,
            [name]: {
              name: fileInfo.originalFilename,
              size: fileInfo.filesize,
              fileUuid,
            },
          },
        }));
      }
    }

    unmountLoadingMsg();
  };

  componentDidUpdate = (prevProps) => {
    const {
      isActive,
      setCurrentStepValid,
      formData: { additionalDocuments },
    } = this.props;
    const { isActive: wasActive } = prevProps;
    const { selectedFiles } = this.state;

    // alle Dokument-Infos laden, falls es welche gibt
    if (
      isActive &&
      selectedFiles &&
      _.size(selectedFiles) === 0 &&
      additionalDocuments &&
      _.size(additionalDocuments) > 0
    ) {
      this.loadFileInfos(additionalDocuments);
    }

    if (isActive && isActive !== wasActive) {
      setCurrentStepValid(true);
    }
  };

  render() {
    const { selectedFiles } = this.state;
    const {
      currentLang,
      formData,
      router: { params },
      onChangeFormData,
    } = this.props;

    const { clientData } = formData;

    let firstName = "";
    let lastName = "";

    if (clientData) {
      firstName = clientData.firstName;
      lastName = clientData.lastName;
    }

    const uploadProps = (doc) => ({
      accept: ".jpg,.jpeg,.pdf",
      className: "uploader",
      multiple: false,
      name: "file",
      action: "",
      beforeUpload: async (file) => {
        if (file && file.size && file.size < 15 * 1024 * 1024) {
          this.setState({ loading: true });

          const unmountLoadingMessageCb = message.loading({
            content: strings[currentLang].UPLOADING_DOCUMENT,
          });

          try {
            const response = await uploadFile(params.formId, file);

            const status = response.status;

            if (status !== 200) {
              message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              unmountLoadingMessageCb();
            } else {
              const { success, uuid } = response.data;

              if (success && uuid) {
                this.setState(
                  (state) => ({
                    selectedFiles: {
                      ...state.selectedFiles,
                      [doc.name]: {
                        file,
                        fileUuid: uuid,
                        name: file.name,
                        size: file.size,
                      },
                    },
                  }),
                  this.validate
                );

                message.success(
                  strings[currentLang].SUCCESS_UPLOADING_DOCUMENT
                );

                onChangeFormData("additionalDocuments", {
                  ...formData.additionalDocuments,
                  [doc.name]: { fileUuid: uuid },
                });
              } else {
                message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              }
            }
          } catch {
            message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
            unmountLoadingMessageCb();
          }
        } else {
          message.error(strings[currentLang].UPLOADING_DOCUMENT_TOO_BIG);
        }
      },
      showUploadList: false,
    });

    return (
      <>
        <ReactMarkdown>
          {strings[
            currentLang
          ].jur.STEP_PLEASE_UPLOAD_THE_FOLLOWING_INFORMATION.replace(
            "[NAME_LEGAL_ENTITY]",
            formData.clientData && formData.clientData.nameLegalEntity
              ? formData.clientData.nameLegalEntity
              : ""
          )}
        </ReactMarkdown>
        <Table
          rowKey="name"
          pagination={false}
          columns={[
            { key: "name", dataIndex: "title", width: "60%" },
            {
              key: "file",
              render: (doc) => {
                const { selectedFiles } = this.state;

                if (selectedFiles && selectedFiles[doc.name]) {
                  return (
                    <>
                      <Button
                        type="link"
                        onClick={() => {
                          this.handleFileDownload(
                            `${
                              process.env.PUBLIC_URL
                            }/api/kycForm/previewUploadedDocument/${
                              params.formId
                            }/${selectedFiles[doc.name].fileUuid}`,
                            selectedFiles[doc.name].name
                          );
                        }}
                      >
                        {strings[currentLang].PREVIEW}
                      </Button>
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          this.setState((s) => {
                            const { [doc.name]: x, ...newSelectedFiles } =
                              s.selectedFiles;
                            return {
                              selectedFiles: newSelectedFiles,
                            };
                          });
                        }}
                      >
                        {strings[currentLang].REMOVE}
                      </Button>
                    </>
                  );
                }

                return (
                  <Upload {...uploadProps(doc)}>
                    <Button>{strings[currentLang].SELECT_FILE}</Button>
                  </Upload>
                );
              },
              align: "center",
            },
          ]}
          dataSource={[
            {
              name: "currentCompanyRegisterExtract",
              title: strings[currentLang].jur.CURRENT_COMPANY_REGISTER_EXTRACT,
            },
            {
              name: "companysArticlesOfAccosiation",
              title: strings[currentLang].jur.COMPANYS_ARTICLES_OF_ASSOCIATION,
            },
            {
              name: "companysLastAuditedFinancialStatements",
              title:
                strings[currentLang].jur
                  .COMPANYS_LAST_AUDITED_FINANCIAL_STATEMENTS,
            },
            {
              name: "organizationChartShareholders",
              title: strings[currentLang].jur.ORGANIZATION_CHART_SHAREHOLDERS,
            },
            {
              name: "currentExtractFromTheBenificialOwnerRegister",
              title:
                strings[currentLang].jur
                  .CURRENT_EXTRACT_FROM_THE_BENIFICIAL_OWNER_REGISTER,
            },
          ]}
          showHeader={false}
        />
      </>
    );
  }
}

JurZusaetzlicheDokumenteUpload.propTypes = {
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    additionalDocuments: PropTypes.any,
    clientData: PropTypes.shape({
      firstName: PropTypes.any,
      lastName: PropTypes.any,
      nameLegalEntity: PropTypes.string,
    }),
  }),
  isActive: PropTypes.bool,
  setCurrentStepValid: PropTypes.func,
  router: PropTypes.any,
  onChangeFormData: PropTypes.func,
};

export default withRouter(JurZusaetzlicheDokumenteUpload);
