import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, message, Modal, Row, Spin } from "antd";
import {
  LeftOutlined,
  QuestionCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import JurAusfuellerInformation from "./steps/jur/JurAusfuellerInformation";
import JurGeschaeftsfuehrer from "./steps/jur/JurGeschaeftsfuehrer/JurGeschaeftsfuehrer";
import JurGeschaeftsfuehrerDokumentUpload from "./steps/jur/JurGeschaeftsfuehrerDokumentUpload";
import JurKontaktperson from "./steps/jur/JurKontaktPerson/JurKontaktPerson";
import JurMandantSteuerpflichtig from "./steps/jur/JurMandantSteuerpflichtig";
import JurPEP from "./steps/jur/JurPEP";
import JurPEP2 from "./steps/jur/JurPEP2";
import JurRechtsgeschaefte from "./steps/jur/JurRechtsgeschaefte";
import JurStammdaten from "./steps/jur/JurStammdaten";
import JurWirtschaftlicherEigentuemer from "./steps/jur/JurWirtschaftlicherEigentuemer/JurWirtschaftlicherEigentuemer";
import JurWirtschaftlicherEigentuemerDokumentUpload from "./steps/jur/JurWirtschaftlicherEigentuemerDokumentUpload";
import JurZusaetzlicheDokumenteUpload from "./steps/jur/JurZusaetzlicheDokumenteUpload";
import JurZusammenfassung from "./steps/jur/JurZusammenfassung";
import NatAusfuellerInformation from "./steps/nat/NatAusfuellerInformation";
import NatDokumentUpload from "./steps/nat/NatDokumentUpload";
import NatMandantSteuerpflichtig from "./steps/nat/NatMandantSteuerpflichtig";
import NatPEP from "./steps/nat/NatPEP";
import NatRechtsgeschaefte from "./steps/nat/NatRechtsgeschaefte";
import NatStammdaten from "./steps/nat/NatStammdaten";
import NatZusammenfassung from "./steps/nat/NatZusammenfassung";
import StepNatJurPerson from "./steps/StepNatJurPerson";
import strings from "./locale/strings.json";

// Core modules imports are same as usual
import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { getAccessToken } from "./api/jwt";
import withRouter from "./util/withRouter";
import { getFormDataForId, saveCurrentState, submitForm } from "./api/api";
import StepAmbHochladen from "./steps/StepAmbHochladen";
import ReactMarkdown from "react-markdown";

class Steps extends Component {
  state = {
    loading: false,
    swiperInstance: null,
    formData: {},
    validSteps: { 0: false },
    isAuthenticated: null,
    ambDocumentUrl: null,
  };

  handleChangeFormData = (key, value, callback) => {
    if (key === "clientType") {
      const { onClientTypeChange } = this.props;
      onClientTypeChange(value);
    }

    this.setState(
      (state) => ({
        formData: { ...state.formData, [key]: value },
      }),
      callback
    );
  };

  gotoPrevStep = () => {
    const { swiperInstance } = this.state;
    swiperInstance.slidePrev(400);
  };

  gotoNextStep = async () => {
    const { swiperInstance } = this.state;
    if (swiperInstance.activeIndex !== swiperInstance.slides.length - 1) {
      swiperInstance.slideNext(400);

      if (swiperInstance.activeIndex === swiperInstance.slides.length - 1) {
        const { onReachedLastStep } = this.props;
        onReachedLastStep();
      }
    } else {
      const { currentLang } = this.props;
      const { formData } = this.state;

      // submit form
      Modal.confirm({
        width: 600,
        centered: true,
        title: strings[currentLang].SUBMIT_MODAL_TITLE,
        content: (
          <ReactMarkdown>
            {strings[currentLang].SUBMIT_MODAL_CONTENT.replace(
              "[USER_DATA_EMAIL]",
              formData.userData.email
            )}
          </ReactMarkdown>
        ),
        icon: <QuestionCircleOutlined />,
        onOk: async () => {
          const {
            router: { params, navigate },
          } = this.props;
          const { formData } = this.state;
          this.setState({ loading: true });

          const response = submitForm(params.formId, formData);

          if (response != null) {
            const { success, msg, error } = (await response).data;

            this.setState({ loading: false });
            if (success) {
              message.success(msg);
              const { onSubmit } = this.props;
              if (onSubmit) onSubmit();
              navigate(`/submitted/${params.formId}`);
            } else {
              message.error(error);
            }
          }
        },
        okText: strings[currentLang].SUBMIT,
        cancelText: strings[currentLang].CANCEL,
      });
    }
  };

  isNextStepValid = () => {
    const { currentStep } = this.props;

    return this.isStepValid(currentStep);
  };

  isStepValid = (stepIndex) => {
    const { validSteps } = this.state;

    return validSteps[stepIndex];
  };

  setCurrentStepValid = (valid) => {
    const { validSteps } = this.state;
    const { currentStep } = this.props;

    this.setState({ validSteps: { ...validSteps, [currentStep]: valid } });
  };

  handleSaveTmpForm = async () => {
    this.setState({ loading: true });
    const { formData } = this.state;
    const { router } = this.props;
    const { params } = router;
    const resPromise = saveCurrentState(params.formId, formData);

    if (resPromise != null) {
      const { success, error, msg } = (await resPromise).data;
      if (success) message.success(msg);
      else message.error(error || "Error saving the current form data.");
    }

    this.setState({ loading: false });
  };

  componentDidUpdate = (prevProps) => {
    const { currentStep } = this.props;
    const { currentStep: prevStep } = prevProps;

    if (currentStep != null && currentStep !== prevStep) {
      const { swiperInstance } = this.state;
      swiperInstance.slideTo(currentStep);
    }
  };

  componentDidMount = async () => {
    const loadingMsgUnmount = message.loading("Loading form data ...");
    const token = getAccessToken();
    this.setState({ isAuthenticated: token != null });

    const { router, onLanguageChange } = this.props;
    const { navigate, params } = router;

    if (token == null) {
      navigate(`/form/${params.formId}/auth`);
    } else {
      // token überprüfen
      const { formId } = params;

      try {
        const form = (await getFormDataForId(formId)).data;

        if (form && form.language) {
          onLanguageChange(form.language);
        }

        if (form.success !== undefined && form.success === false) {
          navigate(`/form/${formId}/auth`);
        } else if (form.success === undefined) {
          const { kycForm, ambDocument } = form;
          const { onClientTypeChange } = this.props;
          if (kycForm && kycForm.clientType)
            onClientTypeChange(kycForm.clientType);

          this.setState({ formData: kycForm, ambDocumentUrl: ambDocument });
        }
      } catch (err) {
        console.log("error", err);
        if (err.response && err.response.status != null) {
          navigate(`/form/${formId}/auth`);
        } else {
          message.error("Error fetching data. Please try again later.");
        }
      }
    }

    loadingMsgUnmount();
  };

  render() {
    const { formData, swiperInstance, loading, ambDocumentUrl } = this.state;
    const { currentLang, onStepChange } = this.props;

    const CT = formData && formData.clientType;

    // if (isAuthenticated === false) <Navigate
    return (
      <>
        <Spin spinning={loading} wrapperClassName="my-swiper-wrapper">
          <Swiper
            allowTouchMove={false}
            pagination={{
              type: "progressbar",
            }}
            navigation={false}
            className="my-swiper"
            modules={[Pagination]}
            onSwiper={(swiper) => {
              swiper.on("activeIndexChange", (swiper) => {
                this.setState({
                  swiperInstance: swiper,
                });
                onStepChange(swiper.activeIndex);
              });
              this.setState({ swiperInstance: swiper });
            }}
          >
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => (
                <StepNatJurPerson
                  isActive={isActive}
                  currentLang={currentLang}
                  onChangeFormData={this.handleChangeFormData}
                  resetFormData={() => {
                    this.setState({
                      formData: { clientType: formData.clientType },
                    });
                  }}
                  setCurrentStepValid={this.setCurrentStepValid}
                  formData={formData}
                  ambDocumentUrl={ambDocumentUrl}
                />
              )}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatStammdaten
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurStammdaten
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatMandantSteuerpflichtig
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurMandantSteuerpflichtig
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatRechtsgeschaefte
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurRechtsgeschaefte
                          formData={formData}
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatPEP
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurGeschaeftsfuehrer
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatDokumentUpload
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurPEP
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <NatAusfuellerInformation
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurGeschaeftsfuehrerDokumentUpload
                          isActive={isActive}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          formData={formData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            <SwiperSlide tabIndex={-1}>
              {({ isActive }) => {
                if (CT) {
                  return (
                    <>
                      {CT === "nat" && (
                        <StepAmbHochladen
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                      {CT === "jur" && (
                        <JurWirtschaftlicherEigentuemer
                          isActive={isActive}
                          formData={formData}
                          currentLang={currentLang}
                          onChangeFormData={this.handleChangeFormData}
                          setCurrentStepValid={this.setCurrentStepValid}
                        />
                      )}
                    </>
                  );
                }
              }}
            </SwiperSlide>
            {CT && (
              <SwiperSlide tabIndex={-1}>
                {({ isActive }) => {
                  if (CT) {
                    return (
                      <>
                        {CT === "nat" && (
                          <NatZusammenfassung
                            isActive={isActive}
                            currentLang={currentLang}
                            onChangeFormData={this.handleChangeFormData}
                            formData={formData}
                            setCurrentStepValid={this.setCurrentStepValid}
                          />
                        )}
                        {CT === "jur" && (
                          <JurPEP2
                            isActive={isActive}
                            formData={formData}
                            currentLang={currentLang}
                            onChangeFormData={this.handleChangeFormData}
                            setCurrentStepValid={this.setCurrentStepValid}
                          />
                        )}
                      </>
                    );
                  }
                }}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <JurWirtschaftlicherEigentuemerDokumentUpload
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <JurKontaktperson
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <JurAusfuellerInformation
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <JurZusaetzlicheDokumenteUpload
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <StepAmbHochladen
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
            {CT && CT === "jur" && (
              <SwiperSlide>
                {({ isActive }) => (
                  <JurZusammenfassung
                    isActive={isActive}
                    formData={formData}
                    currentLang={currentLang}
                    onChangeFormData={this.handleChangeFormData}
                    setCurrentStepValid={this.setCurrentStepValid}
                  />
                )}
              </SwiperSlide>
            )}
          </Swiper>
          <Row justify="space-between" className="footer-controls">
            <Button
              icon={<LeftOutlined />}
              onClick={this.gotoPrevStep}
              disabled={!swiperInstance || swiperInstance.activeIndex === 0}
            >
              {strings[currentLang].BACK}
            </Button>
            <Button type="link" onClick={this.handleSaveTmpForm}>
              {strings[currentLang].SAVE_FORM}
            </Button>
            <Button
              onClick={this.gotoNextStep}
              disabled={!this.isNextStepValid()}
            >
              {swiperInstance &&
              swiperInstance.activeIndex !==
                swiperInstance.slides.length - 1 ? (
                <>
                  {strings[currentLang].NEXT} <RightOutlined />
                </>
              ) : (
                strings[currentLang].SUBMIT
              )}
            </Button>
          </Row>
        </Spin>
      </>
    );
  }
}

Steps.propTypes = {
  currentLang: PropTypes.oneOf(["de", "en"]),
  router: PropTypes.any,
  onClientTypeChange: PropTypes.func,
  onStepChange: PropTypes.func,
  currentStep: PropTypes.number,
  onLanguageChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onReachedLastStep: PropTypes.func,
};

export default withRouter(Steps);
