import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row, Select } from "antd";
import strings from "../../../locale/strings.json";
import CustomForm from "../../../util/CustomForm";

class JurWirtschaftlicherEigentuemerCard extends Component {
  state = { allFieldsValid: false };

  validate = () => {
    const { allFieldsValid } = this.state;
    const { onValidated } = this.props;

    if (allFieldsValid === true) {
      onValidated(true);
    } else {
      onValidated(false);
    }
  };

  render() {
    const {
      currentLang,
      onChangeBeneficialOwnerData,
      beneficialOwnerData,
      readOnly,
    } = this.props;

    const formLayout = {
      wrapperCol: { xs: 24, xl: 24 },
      labelCol: { xs: 24, xl: 24 },
    };

    const formItems = [
      {
        name: "firstName",
        required: true,
        label: strings[currentLang].jur.FIRST_NAME,
        onChange: onChangeBeneficialOwnerData,
        defaultValue: beneficialOwnerData.firstName,
        readOnly,
      },
      {
        name: "lastName",
        required: true,
        label: strings[currentLang].jur.LAST_NAME,
        onChange: onChangeBeneficialOwnerData,
        defaultValue: beneficialOwnerData.lastName,
        readOnly,
      },
      {
        name: "dateOfBirth",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: strings[currentLang].jur.DATE_OF_BIRTH,
        type: "birthday",
        defaultValue: beneficialOwnerData.dateOfBirth,
        readOnly,
      },
      {
        name: "nationality",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: strings[currentLang].jur.NATIONALITY,
        type: "country",
        defaultValue: beneficialOwnerData.nationality,
        readOnly,
      },
      {
        name: "street",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: `${strings[currentLang].jur.RESIDENTIAL_ADDRESS}`,
        validationFunc: (val) => {
          const x = val.trim();
          return x.indexOf(" ") > 0;
        },
        errorMsg: strings[currentLang].ERROR_MSG_ADDRESS,
        defaultValue: beneficialOwnerData.street,
        readOnly,
      },
      {
        name: "zip",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: `${strings[currentLang].jur.POSTAL_CODE}`,
        defaultValue: beneficialOwnerData.zip,
        readOnly,
      },
      {
        name: "city",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: `${strings[currentLang].jur.CITY}`,
        defaultValue: beneficialOwnerData.city,
        readOnly,
      },
      {
        name: "country",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: `${strings[currentLang].jur.COUNTRY}`,
        type: "country",
        defaultValue: beneficialOwnerData.country,
        readOnly,
      },
      {
        name: "email",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: strings[currentLang].jur.EMAIL_ADDRESS,
        type: "email",
        defaultValue: beneficialOwnerData.email,
        readOnly,
      },
      {
        name: "phone",
        required: true,
        onChange: onChangeBeneficialOwnerData,
        label: strings[currentLang].nat.PHONE_NUMBER,
        type: "phone",
        help: strings[currentLang].HINT_PHONE_NUMBER,
        defaultValue: beneficialOwnerData.phone,
        readOnly,
      },
    ];

    return (
      <>
        <CustomForm
          currentLang={currentLang}
          formItems={formItems}
          onValid={() => {
            this.setState({ allFieldsValid: true }, this.validate);
          }}
          onInvalid={() => {
            this.setState({ allFieldsValid: false }, this.validate);
          }}
        />
      </>
    );
  }
}

JurWirtschaftlicherEigentuemerCard.propTypes = {
  beneficialOwnerData: PropTypes.any,
  currentLang: PropTypes.oneOf(["de", "en"]),
  onChangeBeneficialOwnerData: PropTypes.func,
  onValidated: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default JurWirtschaftlicherEigentuemerCard;
