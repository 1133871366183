import PropTypes from "prop-types";
import React, { Component } from "react";
import strings from "../../locale/strings.json";
import countries from "i18n-iso-countries";
import countriesDE from "i18n-iso-countries/langs/de.json";
import countriesEN from "i18n-iso-countries/langs/en.json";
import DomicileHapitualResidenceDefinitionEN from "../../locale/Domicile_Hapitual-Residence_en.md";
import DomicileHapitualResidenceDefinitionDE from "../../locale/Domicile_Hapitual-Residence_de.md";
import TaxationAustriaLegalEntityEN from "../../locale/Taxation_Austria_Legal_Entity_en.md";
import TaxationAustriaLegalEntityDE from "../../locale/Taxation_Austria_Legal_Entity_de.md";
import { Radio, Select, Space } from "antd";
import ReactMarkdown from "react-markdown";

class JurMandantSteuerpflichtig extends Component {
  state = {
    countries: null,
    selectedCountry: null,
    selectedType: null,
    selectedAnswer: null,
    taxationInAustriaDefinition: null,
  };

  constructor(props) {
    super(props);

    const {
      currentLang,
      formData: { clientType, clientData },
    } = props;

    countries.registerLocale(currentLang === "de" ? countriesDE : countriesEN);
    this.state.countries = countries.getNames(currentLang, {
      select: "official",
    });

    if (clientType && clientType === "nat") {
      fetch(
        currentLang === "en"
          ? DomicileHapitualResidenceDefinitionEN
          : DomicileHapitualResidenceDefinitionDE
      )
        .then((res) => res.text())
        .then((text) => {
          this.state.taxationInAustriaDefinition = text;
        });
    } else if (clientType && clientType === "jur") {
      if (clientData && clientData.nameLegalEntity) {
        fetch(
          currentLang === "en"
            ? TaxationAustriaLegalEntityEN
            : TaxationAustriaLegalEntityDE
        )
          .then((res) => res.text())
          .then((text) => {
            this.state.taxationInAustriaDefinition = text.replace(
              "[NAME_LEGAL_ENTITY]",
              clientData.nameLegalEntity
            );
          });
      }
    }
  }

  validate = () => {
    const { setCurrentStepValid, formData, isActive } = this.props;
    const { selectedAnswer } = this.state;

    if (isActive) {
      if (selectedAnswer != null) {
        if (selectedAnswer === "1") {
          setCurrentStepValid(true);
        } else if (
          formData &&
          formData.clientSubjectToTaxationInCountry != null
        ) {
          setCurrentStepValid(true);
        } else {
          setCurrentStepValid(false);
        }
      } else {
        setCurrentStepValid(false);
      }
    }
  };

  insertNameIntoHeader = (nameLegalEntity, header) =>
    header.replace("[NAME_LEGAL_ENTITY]", nameLegalEntity);

  componentDidUpdate = (prevProps) => {
    const {
      isActive,
      formData: { clientType, clientData },
    } = this.props;
    const { isActive: wasActive } = prevProps;
    const { selectedAnswer, selectedCountry } = this.state;

    if (isActive && !wasActive && clientType) {
      const { currentLang } = this.props;

      if (clientType && clientType === "nat") {
        fetch(
          currentLang === "en"
            ? DomicileHapitualResidenceDefinitionEN
            : DomicileHapitualResidenceDefinitionDE
        )
          .then((res) => res.text())
          .then((text) => {
            this.setState({ taxationInAustriaDefinition: text });
          });
      } else if (clientType && clientType === "jur") {
        if (clientData && clientData.nameLegalEntity) {
          fetch(
            currentLang === "en"
              ? TaxationAustriaLegalEntityEN
              : TaxationAustriaLegalEntityDE
          )
            .then((res) => res.text())
            .then((text) => {
              this.setState({
                taxationInAustriaDefinition: text.replace(
                  "[NAME_LEGAL_ENTITY]",
                  clientData.nameLegalEntity
                ),
              });
            });
        }
      }
    }

    if (isActive) {
      if (
        prevProps.formData.clientSubjectToTaxationInCountry !==
        this.props.formData.clientSubjectToTaxationInCountry
      ) {
        this.validate();
      }

      if (
        !selectedAnswer &&
        !selectedCountry &&
        this.props.formData.clientSubjectToTaxationInCountry
      ) {
        const cc = this.props.formData.clientSubjectToTaxationInCountry;
        this.setState(
          {
            selectedCountry: cc,
            selectedAnswer: cc === "AT" ? "1" : "0",
          },
          this.validate
        );
      }
    }
  };

  render() {
    const {
      countries,
      selectedAnswer,
      taxationInAustriaDefinition,
      selectedCountry,
    } = this.state;
    const { currentLang, formData, onChangeFormData } = this.props;

    const { clientData } = formData;

    let nameLegalEntity = "";

    if (clientData) {
      nameLegalEntity = clientData.nameLegalEntity;
    }

    return (
      <>
        <h2>
          {this.insertNameIntoHeader(
            nameLegalEntity,
            strings[currentLang].jur.STEP_CLIENT_SUBJECT_TO_TAXATION
          )}
        </h2>
        <Space direction="vertical" size="large">
          <Radio.Group
            onChange={(e) => {
              this.setState({ selectedAnswer: e.target.value }, this.validate);
              onChangeFormData(
                "clientSubjectToTaxationInAustria",
                e.target.value
              );

              if (e.target.value === "1") {
                onChangeFormData("clientSubjectToTaxationInCountry", "AT");
              } else {
                onChangeFormData("clientSubjectToTaxationInCountry", null);
                this.setState({ selectedCountry: null });
              }
            }}
            value={selectedAnswer}
          >
            <Space direction="vertical">
              <Radio value="1">{strings[currentLang].YES}</Radio>
              <Radio value="0">{strings[currentLang].NO}</Radio>
            </Space>
          </Radio.Group>

          <div
            className={
              selectedAnswer && selectedAnswer === "0" ? "fade-in" : "fade-out"
            }
          >
            <h3>
              {this.insertNameIntoHeader(
                nameLegalEntity,
                strings[currentLang].jur.STEP_SELECT_COUNTRY_FOR_TAXATION
              )}
            </h3>
            <Select
              placeholder={strings[currentLang].nat.SELECT_COUNTRY}
              options={Object.keys(countries)
                .filter((cc) => cc !== "AT")
                .map((countryCode) => ({
                  label: countries[countryCode],
                  value: countryCode,
                }))}
              optionFilterProp="label"
              showSearch
              value={selectedCountry}
              onChange={(countryCode) => {
                this.setState({ selectedCountry: countryCode });
                onChangeFormData(
                  "clientSubjectToTaxationInCountry",
                  countryCode
                );
              }}
            />
          </div>
        </Space>
        {taxationInAustriaDefinition && (
          <div
            style={{
              marginTop: selectedAnswer && selectedAnswer === "0" ? "48px" : 0,
            }}
          >
            <ReactMarkdown>{taxationInAustriaDefinition}</ReactMarkdown>
          </div>
        )}
      </>
    );
  }
}

JurMandantSteuerpflichtig.propTypes = {
  currentLang: PropTypes.string,
  formData: PropTypes.shape({
    clientData: PropTypes.shape({
      nameLegalEntity: PropTypes.any,
    }),
    clientType: PropTypes.string,
    clientSubjectToTaxationInCountry: PropTypes.any,
  }),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
};

export default JurMandantSteuerpflichtig;
