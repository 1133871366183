import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { getAuthTokenForFormAndPin } from "./api/api";
import { getAccessToken, removeAccessToken, saveAccessToken } from "./api/jwt";

let AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [jwt, setJwt] = useState(null);

  const tryPin = async (formId, pincode) => {
    const { token } = (await getAuthTokenForFormAndPin(formId, pincode)).data;
    if (token) {
      saveAccessToken(token);
      setJwt(token);
      return true;
    }

    return false;
  };

  const signout = () => {
    removeAccessToken();
  };

  return (
    <AuthContext.Provider value={{ jwt, setJwt, tryPin, signout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();
  const params = useParams();

  if (!auth.jwt) {
    // checken, ob es einen Token gibt
    const token = getAccessToken();

    if (!token) {
      return (
        <Navigate
          to={`/form/${params.formId}/auth`}
          state={{ from: location }}
          replace
        />
      );
    }
  }

  return children;
}

export { AuthContext };

RequireAuth.propTypes = {
  children: PropTypes.any,
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};
