import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "antd";
import { BankOutlined, FilePdfOutlined, UserOutlined } from "@ant-design/icons";
import strings from "../locale/strings.json";
import ReactMarkdown from "react-markdown";

class StepNatJurPerson extends Component {
  state = {
    selectedType: null,
  };

  constructor(props) {
    super(props);
  }

  checkIfFormDataExists = (callback) => {
    const { formData } = this.props;

    if (formData && Object.keys(formData).length > 2) {
      Modal.confirm({
        title: "Attention",
        centered: true,
        content:
          "Changing the type of entity clears the form data. Do you want to continue?",
        onOk: () => {
          const { resetFormData } = this.props;
          resetFormData();
          callback();
        },
        okText: "Yes, I want to change it",
        cancelText: "Cancel",
      });
    } else {
      callback();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { formData } = this.props;
    const { formData: prevFormData } = prevProps;

    if (
      formData &&
      formData.clientType &&
      (!prevFormData || formData.clientType !== prevFormData.clientType)
    ) {
      this.setState({ selectedType: formData.clientType });
      const { setCurrentStepValid } = this.props;
      setCurrentStepValid(true);
    }
  };

  render() {
    const { selectedType } = this.state;
    const {
      currentLang,
      onChangeFormData,
      setCurrentStepValid,
      ambDocumentUrl,
    } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          paddingTop: "24px",
        }}
      >
        <ReactMarkdown>{strings[currentLang].WELCOME_TEXT}</ReactMarkdown>

        <h2 style={{ paddingTop: "0", marginTop: 0 }}>
          {strings[currentLang].SELECT_CLIENT_TYPE_HEADER}
        </h2>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            rowGap: "12px",
          }}
        >
          <Button.Group>
            <Button
              type={selectedType === "nat" ? "primary" : "default"}
              icon={<UserOutlined />}
              onClick={() => {
                this.checkIfFormDataExists(() => {
                  onChangeFormData("clientType", "nat");
                  this.setState({ selectedType: "nat" }, () => {
                    setCurrentStepValid(true);
                  });
                });
              }}
            >
              {strings[currentLang].NATURAL_PERSON}
            </Button>
            <Button
              type={selectedType === "jur" ? "primary" : "default"}
              icon={<BankOutlined />}
              onClick={() => {
                this.checkIfFormDataExists(() => {
                  onChangeFormData("clientType", "jur");
                  this.setState({ selectedType: "jur" }, () => {
                    setCurrentStepValid(true);
                  });
                });
              }}
            >
              {strings[currentLang].LEGAL_ENTITY}
            </Button>
          </Button.Group>
        </div>
        {selectedType != null && (
          <Row style={{ marginTop: "24px" }}>
            <Col
              xs={24}
              md={4}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "12px",
              }}
            >
              <FilePdfOutlined style={{ fontSize: "2rem", color: "coral" }} />
            </Col>
            <Col xs={24} md={20}>
              <ReactMarkdown linkTarget="_blank">
                {strings[currentLang][
                  selectedType
                ].WELCOME_INFORMATION_TEXT.replace(
                  "[AMB_DOCUMENT_URL]",
                  ambDocumentUrl
                )}
              </ReactMarkdown>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

StepNatJurPerson.propTypes = {
  currentLang: PropTypes.oneOf(["de", "en"]),
  onChangeFormData: PropTypes.func,
  resetFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
  formData: PropTypes.any,
  ambDocumentUrl: PropTypes.string,
};

export default StepNatJurPerson;
