import React from "react";

const InformationOhneFormular = () => {
  return (
    <p>
      Please use the link we&apos;ve sent you via e-mail to fill in all the
      information.
    </p>
  );
};

export default InformationOhneFormular;
