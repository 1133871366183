import PropTypes from "prop-types";
import React, { Component } from "react";
import strings from "../../locale/strings.json";
import CustomForm from "../../util/CustomForm";

class JurStammdaten extends Component {
  state = { clientData: {}, allFieldsValid: false };

  validate = () => {
    const { isActive } = this.props;

    if (isActive) {
      const { setCurrentStepValid } = this.props;
      const { allFieldsValid } = this.state;

      setCurrentStepValid(allFieldsValid);
    }
  };

  componentDidMount = () => {
    const { formData } = this.props;
    const { clientData } = formData;

    if (clientData) {
      this.setState({ clientData });
    }
  };

  render() {
    const { currentLang, formData, isActive } = this.props;

    const { clientData } = formData;

    const formClientData = clientData || {};

    const onValChange = (key, value) => {
      const { clientData } = this.state;
      const { onChangeFormData } = this.props;

      this.setState(
        {
          clientData: {
            ...clientData,
            [key]: value,
          },
        },
        () => {
          onChangeFormData("clientData", this.state.clientData);
        }
      );
    };

    const formItems = [
      {
        name: "nameLegalEntity",
        required: true,
        label: strings[currentLang].jur.NAME_LEGAL_ENTITY,
        hint: strings[currentLang].jur.HINT_CLIENT_NAME,
        onChange: onValChange,
        defaultValue: formClientData.nameLegalEntity,
      },
      {
        name: "registrationNumber",
        required: true,
        label: strings[currentLang].jur.REGISTRATION_NUMBER,
        maxLength: 24,
        onChange: onValChange,
        defaultValue: formClientData.registrationNumber,
      },
      {
        name: "businessAddress",
        required: true,
        label: strings[currentLang].jur.BUSINESS_ADDRESS,
        onChange: onValChange,
        validationFunc: (val) => {
          const x = val.trim();
          return x.indexOf(" ") > 0;
        },
        errorMsg: strings[currentLang].ERROR_MSG_ADDRESS,
        defaultValue: formClientData.businessAddress,
      },
      {
        name: "postalCode",
        required: true,
        label: strings[currentLang].jur.POSTAL_CODE,
        onChange: onValChange,
        defaultValue: formClientData.postalCode,
        // validationFunc: (val) => /^\d+$/.test(val),
      },
      {
        name: "city",
        required: true,
        label: strings[currentLang].jur.CITY,
        onChange: onValChange,
        validationFunc: (val) => /^[a-zA-Z ]+$/.test(val),
        errorMsg: strings[currentLang].ERROR_MSG_ONLY_LETTERS_ALLOWED,
        defaultValue: formClientData.city,
      },
      {
        name: "countryOfRegistration",
        required: true,
        label: strings[currentLang].jur.COUNTRY_OF_REGISTRATION,
        onChange: onValChange,
        type: "country",
        defaultValue: formClientData.countryOfRegistration,
      },
      {
        name: "invoiceEmail",
        required: true,
        label: strings[currentLang].jur.EMAIL_ADDRESS,
        onChange: onValChange,
        type: "email",
        help: strings[currentLang].HINT_EMAIL_ADDRESS_FOR_INVOICES,
        hint: strings[currentLang].HINT_EMAIL_ADDRESS_FOR_INVOICES_DESC,
        defaultValue: formClientData.invoiceEmail,
      },
      {
        name: "vatNumber",
        label: strings[currentLang].jur.VAT_NUMBER,
        onChange: onValChange,
        type: "vat",
        countryCode:
          (formClientData && formClientData.countryOfRegistration) || null,
        defaultValue:
          (formClientData &&
            formClientData.countryOfRegistration &&
            formClientData.vatNumber) ||
          "",
      },
    ];

    return (
      <>
        <h2>{strings[currentLang].jur.STEP_CLIENT_INFORMATION}</h2>
        <CustomForm
          isActive={isActive}
          formItems={formItems}
          currentLang={currentLang}
          onValid={() => {
            this.setState({ allFieldsValid: true }, this.validate);
          }}
          onInvalid={() => {
            this.setState({ allFieldsValid: false }, this.validate);
          }}
        />
      </>
    );
  }
}

JurStammdaten.propTypes = {
  currentLang: PropTypes.oneOf(["de", "en"]),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  setCurrentStepInvalid: PropTypes.func,
  isActive: PropTypes.bool,
  formData: PropTypes.any,
};

export default JurStammdaten;
