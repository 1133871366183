import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Form, Input, Radio, Row } from "antd";
import strings from "../../locale/strings.json";
import CustomForm from "../../util/CustomForm";
import _ from "lodash";

class JurAusfuellerInformation extends Component {
  state = { countries: null, selectedRadioButton: null };

  validate = () => {};

  handleUserDataChange = (key, value) => {
    const { formData, onChangeFormData } = this.props;
    const { userData } = formData;

    if (userData) {
      onChangeFormData("userData", { ...userData, [key]: value });
    }
  };

  handleRadioButtonsChange = (val) => {
    const { formData, onChangeFormData } = this.props;
    if (val === "other") {
      this.setState({ selectedRadioButton: "other" });
      onChangeFormData("userData", {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      });
    } else {
      let managingDirectors = [];
      let beneficialOwners = [];
      if (formData.managingDirectors)
        managingDirectors = formData.managingDirectors;
      if (formData.beneficialOwners)
        beneficialOwners = formData.beneficialOwners;

      let x = managingDirectors.find((md) => md.key === val);

      if (!x) x = beneficialOwners.find((bo) => bo.key === val);

      if (x) {
        this.setState({ selectedRadioButton: x.key });
        onChangeFormData("userData", {
          firstName: x.firstName,
          lastName: x.lastName,
          email: x.email,
          phone: x.phone,
          key: x.key,
        });
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const { isActive, formData } = this.props;
    const { isActive: wasActive } = prevProps;
    const { userData } = formData;

    if (isActive && !wasActive) {
      // select radio button
      if (userData && _.isObject(userData)) {
        const key = userData.key;
        if (key) {
          this.setState({ selectedRadioButton: key });
        } else {
          this.setState({ selectedRadioButton: "other" });
        }
      }

      this.validate();
    }
  };

  render() {
    const { isActive, currentLang, formData, setCurrentStepValid } = this.props;

    const { selectedRadioButton } = this.state;

    let { userData } = formData;

    if (!userData) userData = {};

    let managingDirectors = [];
    let beneficialOwners = [];

    if (formData.managingDirectors)
      managingDirectors = formData.managingDirectors;
    if (formData.beneficialOwners) beneficialOwners = formData.beneficialOwners;

    const formItems = [
      {
        name: "firstName",
        required: true,
        label: strings[currentLang].jur.FIRST_NAME,
        onChange: this.handleUserDataChange,
        defaultValue: userData.firstName,
        readOnly:
          selectedRadioButton &&
          (beneficialOwners.findIndex((bo) => bo.key === selectedRadioButton) >=
            0 ||
            managingDirectors.findIndex(
              (md) => md.key === selectedRadioButton
            ) >= 0),
      },
      {
        name: "lastName",
        required: true,
        label: strings[currentLang].jur.LAST_NAME,
        onChange: this.handleUserDataChange,
        defaultValue: userData.lastName,
        readOnly:
          selectedRadioButton &&
          (beneficialOwners.findIndex((bo) => bo.key === selectedRadioButton) >=
            0 ||
            managingDirectors.findIndex(
              (md) => md.key === selectedRadioButton
            ) >= 0),
      },
      {
        name: "email",
        required: true,
        onChange: this.handleUserDataChange,
        label: strings[currentLang].jur.EMAIL_ADDRESS,
        type: "email",
        defaultValue: userData.email,
        readOnly:
          selectedRadioButton &&
          (beneficialOwners.findIndex((bo) => bo.key === selectedRadioButton) >=
            0 ||
            managingDirectors.findIndex(
              (md) => md.key === selectedRadioButton
            ) >= 0),
      },
      {
        name: "phone",
        required: true,
        onChange: this.handleUserDataChange,
        label: strings[currentLang].nat.PHONE_NUMBER,
        type: "phone",
        help: strings[currentLang].HINT_PHONE_NUMBER,
        defaultValue: userData.phone,
        readOnly:
          selectedRadioButton &&
          (beneficialOwners.findIndex((bo) => bo.key === selectedRadioButton) >=
            0 ||
            managingDirectors.findIndex(
              (md) => md.key === selectedRadioButton
            ) >= 0),
      },
    ];

    return (
      <>
        <h2>{strings[currentLang].jur.STEP_PROVIDE_USER_INFORMATION}</h2>
        <Row>
          <Col xs={24}>
            <Radio.Group
              onChange={(e) => {
                this.handleRadioButtonsChange(e.target.value);
              }}
              value={selectedRadioButton}
            >
              {managingDirectors && (
                <>
                  {managingDirectors.map((md) => (
                    <Radio key={md.key} value={md.key}>
                      {md.firstName} {md.lastName}
                    </Radio>
                  ))}
                </>
              )}
              {beneficialOwners && (
                <>
                  {beneficialOwners
                    .filter(
                      (bo) =>
                        !managingDirectors ||
                        managingDirectors.findIndex(
                          (md) =>
                            `${md.firstName} ${md.lastName}` ===
                            `${bo.firstName} ${bo.lastName}`
                        ) === -1
                    )
                    .map((bo) => (
                      <Radio value={bo.key} key={bo.key}>
                        {bo.firstName} {bo.lastName}
                      </Radio>
                    ))}
                </>
              )}
              <Radio key="other" value="other">
                {strings[currentLang].OTHER_PERSON}
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        {formData && formData.userData && (
          <Row>
            <Col xs={24}>
              <CustomForm
                isActive={isActive}
                formItems={formItems}
                onValid={() => {
                  setCurrentStepValid(true);
                }}
                onInvalid={() => {
                  setCurrentStepValid(false);
                }}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

JurAusfuellerInformation.propTypes = {
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    beneficialOwners: PropTypes.any,
    managingDirectors: PropTypes.any,
    userData: PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
      key: PropTypes.string,
    }),
  }),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
};

export default JurAusfuellerInformation;
