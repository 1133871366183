import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, message, Upload } from "antd";
import strings from "../../locale/strings.json";
import { formatBytes } from "../../util/util";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import withRouter from "../../util/withRouter";
import _ from "lodash";
import { getUploadedFileInfo, uploadFile } from "../../api/api";
import { getAccessToken } from "../../api/jwt";
import axios from "axios";
import fileDownload from "js-file-download";

class JurWirtschaftlicherEigentuemerDokumentUpload extends Component {
  state = { selectedFiles: {}, isOptional: false };

  checkIfStepIsOptional = () => {
    const { formData } = this.props;
    const { legalServices, otherLegalService } = formData;

    this.setState({
      isOptional:
        (!legalServices ||
          (Array.isArray(legalServices) && legalServices.length === 0)) &&
        otherLegalService,
    });
  };

  validate = () => {
    const { formData, setCurrentStepValid } = this.props;
    const { beneficialOwners } = formData;

    if (beneficialOwners) {
      const { selectedFiles } = this.state;
      setCurrentStepValid(
        beneficialOwners.filter((bo) => !selectedFiles[bo.key]).length === 0
      );
    } else {
      setCurrentStepValid(false);
    }
  };

  loadFileInfoForKeys = async (keys) => {
    const unmountLoadingMsg = message.loading({
      content: "Loading file infos ...",
    });

    const {
      router: { params },
    } = this.props;

    for (let i = 0; i < Object.keys(keys).length; i++) {
      const key = Object.keys(keys)[i];
      const fileUuid = keys[key];

      const { success, fileInfo } = (
        await getUploadedFileInfo(params.formId, fileUuid)
      ).data;

      if (success && fileInfo) {
        this.setState((state) => ({
          selectedFiles: {
            ...state.selectedFiles,
            [key]: { name: fileInfo.originalFilename, size: fileInfo.filesize },
          },
        }));
      }
    }

    unmountLoadingMsg();
  };

  handleFileDownload = async (url, filename) => {
    const unmountLoadingMsg = message.loading({
      content: "Downloading attachment ...",
    });

    const token = getAccessToken();

    try {
      const data = (
        await axios.get(url, {
          headers: { authorization: `Bearer ${token}` },
          responseType: "blob",
        })
      ).data;

      if (data) {
        fileDownload(data, filename);
      }
    } catch {
      message.error("You don't have access to that file.");
    } finally {
      unmountLoadingMsg();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { selectedFiles } = this.state;
    const { isActive: wasActive } = prevProps;
    const { isActive, formData } = this.props;

    if (isActive && !wasActive) {
      const { beneficialOwners } = formData;

      if (
        beneficialOwners &&
        _.size(beneficialOwners) > 0 &&
        _.size(selectedFiles) === 0
      ) {
        const keys = {};

        for (let i = 0; i < _.size(beneficialOwners); i++) {
          if (
            beneficialOwners[i] &&
            beneficialOwners[i].photoId &&
            beneficialOwners[i].photoId.fileUuid
          ) {
            keys[beneficialOwners[i].key] =
              beneficialOwners[i].photoId.fileUuid;
          }
        }

        this.loadFileInfoForKeys(keys).then(() => {
          this.validate();
          this.checkIfStepIsOptional();
        });
      } else {
        this.validate();
        this.checkIfStepIsOptional();
      }
    }
  };

  render() {
    const { selectedFiles, isOptional } = this.state;
    const { currentLang, formData, onChangeFormData } = this.props;

    const {
      router: { params },
    } = this.props;

    const { beneficialOwners } = formData;

    const uploadProps = (bo) => ({
      accept: ".jpg,.jpeg,.pdf",
      className: "uploader",
      name: "file",
      multiple: false,
      action: "",
      beforeUpload: async (file) => {
        if (file && file.size && file.size < 15 * 1024 * 1024) {
          this.setState({ loading: true });

          const unmountLoadingMessageCb = message.loading({
            content: strings[currentLang].UPLOADING_DOCUMENT,
          });

          try {
            const response = await uploadFile(params.formId, file);

            const status = response.status;

            if (status !== 200) {
              message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              unmountLoadingMessageCb();
            } else {
              const { success, uuid } = response.data;

              unmountLoadingMessageCb();

              if (success && uuid) {
                this.setState(
                  (state) => ({
                    selectedFiles: { ...state.selectedFiles, [bo.key]: file },
                  }),
                  this.validate
                );

                message.success(
                  strings[currentLang].SUCCESS_UPLOADING_DOCUMENT
                );

                // das file zum Beneficial Owner hinzufügen
                const idx = beneficialOwners.findIndex(
                  (bo2) => bo2.key === bo.key
                );

                beneficialOwners[idx].photoId = { fileUuid: uuid };

                onChangeFormData("beneficialOwners", beneficialOwners);
              } else {
                message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
              }
            }
          } catch {
            message.error(strings[currentLang].ERROR_UPLOADING_DOCUMENT);
            unmountLoadingMessageCb();
          }
        } else {
          message.error(strings[currentLang].UPLOADING_DOCUMENT_TOO_BIG);
        }
      },
      showUploadList: false,
    });

    return (
      <>
        {isOptional && (
          <h2>
            <ExclamationCircleTwoTone
              twoToneColor="orange"
              style={{ marginRight: "6px" }}
            />
            <b>{strings[currentLang].THIS_STEP_IS_OPTIONAL}</b>
          </h2>
        )}
        <h2>
          {
            strings[currentLang].jur
              .STEP_UPLOAD_OFFICIAL_DOCUMENT_FOR_EACH_BENEFICIAL_OWNER
          }
        </h2>
        {beneficialOwners && (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {beneficialOwners.map((bo, index) => (
              <li
                key={bo.key}
                style={{
                  borderBottom:
                    index < beneficialOwners.length - 1
                      ? "1px solid #dddddd"
                      : 0,
                  padding: "24px 0 32px 0",
                }}
              >
                <span style={{ fontSize: "14pt", fontWeight: "lighter" }}>
                  {bo.firstName} {bo.lastName}
                </span>
                {!selectedFiles[bo.key] && (
                  <div>
                    <Upload.Dragger {...uploadProps(bo)}>
                      <p className="upload-text">
                        {strings[currentLang].CLICK_OR_DRAG_TO_UPLOAD}
                      </p>
                      <p className="upload-hint">
                        {strings[currentLang].CHOOSE_MULTIPLE_FILES}
                      </p>
                    </Upload.Dragger>
                  </div>
                )}
                <div
                  className={!selectedFiles[bo.key] ? "fade-out" : "fade-in"}
                >
                  {selectedFiles[bo.key] && (
                    <>
                      {selectedFiles[bo.key].name} (
                      <i>{formatBytes(selectedFiles[bo.key].size)}</i>){" "}
                      <Button
                        type="link"
                        onClick={() => {
                          this.handleFileDownload(
                            `${process.env.PUBLIC_URL}/api/kycForm/previewUploadedDocument/${params.formId}/${bo.photoId.fileUuid}`,
                            selectedFiles[bo.key].name
                          );
                        }}
                      >
                        {strings[currentLang].PREVIEW}
                      </Button>
                      <Button
                        type="link"
                        danger
                        onClick={() => {
                          this.setState((s) => {
                            const { [bo.key]: x, ...newSelectedFiles } =
                              s.selectedFiles;
                            return {
                              selectedFiles: newSelectedFiles,
                            };
                          }, this.validate);
                        }}
                      >
                        {strings[currentLang].REMOVE}
                      </Button>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </>
    );
  }
}

JurWirtschaftlicherEigentuemerDokumentUpload.propTypes = {
  isActive: PropTypes.bool,
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    beneficialOwners: PropTypes.array,
    legalServices: PropTypes.array,
    otherLegalService: PropTypes.string,
  }),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  router: PropTypes.any,
};

export default withRouter(JurWirtschaftlicherEigentuemerDokumentUpload);
