import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import strings from "./locale/strings.json";
import { getFormDataForId, unlockForm } from "./api/api";

const FormWiederAktivieren = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const { formId } = params;

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState("en");

  const handleUnlockClick = async () => {
    setLoading(true);
    const r = unlockForm(formId);
    if (r != null) {
      const { success, msg, error } = (await r).data;

      if (success) {
        message.success(msg);
        navigate(`/form/${formId}`);
      } else {
        message.error(error);
      }
    }

    setLoading(false);
  };

  const loadFormData = async () => {
    const { language: formLang } = (await getFormDataForId(formId)).data;

    if (formLang) {
      setLanguage(formLang);
    }
  };

  useEffect(() => {
    loadFormData();
  }, []);

  return (
    <div style={{ marginBottom: "48px" }}>
      <h2>{strings[language].REACTIVATE_FORM_TEXT}</h2>
      <Button type="primary" onClick={handleUnlockClick} loading={loading}>
        {strings[language].EDIT_DATA}
      </Button>
    </div>
  );
};

FormWiederAktivieren.propTypes = {
  formId: PropTypes.any,
};

export default FormWiederAktivieren;
