import axios from "axios";
import { getAccessToken } from "./jwt";

function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
}

function jsonToFormData(data) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export const getAuthTokenForFormAndPin = (formId, pincode) =>
  axios.post(
    `/api/kycForm/auth/${formId}`,
    { pincode },
    { headers: { "content-type": "application/json" }, timeout: 5000 }
  );

export const getFormDataForId = (formId) => {
  const token = getAccessToken();

  if (token != null) {
    return axios.get(`/api/kycForm/get/${formId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  return null;
};

export const saveCurrentState = (formId, currentState) => {
  const token = getAccessToken();

  if (token != null) {
    return axios.post(
      `/api/kycForm/tmpSave/${formId}`,
      { formData: currentState },
      {
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      }
    );
  }

  return null;
};

export const uploadFile = (formId, file) => {
  const token = getAccessToken();

  if (token != null) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`/api/kycForm/uploadDocument/${formId}`, formData, {
      headers: { authorization: `Bearer ${token}` },
      "content-type": "multipart/form-data",
    });
  }

  return null;
};

export const getUploadedFileInfo = (formId, fileUuid) => {
  const token = getAccessToken();

  if (token != null) {
    return axios.get(`/api/kycForm/fileInfo/${formId}/${fileUuid}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  return null;
};

export const submitForm = async (formId, currentState) => {
  const token = getAccessToken();

  if (token != null && currentState != null) {
    const r = saveCurrentState(formId, currentState);

    if (r != null) {
      const { success, error } = (await r).data;

      if (success) {
        return axios.get(`/api/kycForm/submit/${formId}`, {
          headers: { authorization: `Bearer ${token}` },
        });
      }

      return null;
    }
  }

  return null;
};

export const unlockForm = async (formId) => {
  const token = getAccessToken();

  if (token != null) {
    return axios.get(`/api/kycForm/unlockForm/${formId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  return null;
};

export const confirmForm = async (formId) => {
  const token = getAccessToken();

  if (token != null) {
    return axios.get(`/api/kycForm/confirm/${formId}`, {
      headers: { authorization: `Bearer ${token}` },
    });
  }

  return null;
};
