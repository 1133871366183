import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import strings from "../../locale/strings.json";
import CustomForm from "../../util/CustomForm";

class NatStammdaten extends Component {
  state = { countries: null, clientData: {}, allFieldsValid: false };

  validate = () => {
    const { isActive } = this.props;

    if (isActive) {
      const { setCurrentStepValid } = this.props;
      const { allFieldsValid } = this.state;

      setCurrentStepValid(allFieldsValid);
    }
  };

  onValChange = (key, value) => {
    const { clientData } = this.state;
    const { onChangeFormData } = this.props;

    this.setState(
      {
        clientData: {
          ...clientData,
          [key]: value,
        },
      },
      () => {
        onChangeFormData(
          "clientData",
          { ...clientData, [key]: value },
          this.validate
        );
      }
    );
  };

  componentDidUpdate = (prevProps) => {
    const { formData, isActive } = this.props;
    const { isActive: wasActive } = prevProps;

    if (isActive && !wasActive && formData && formData.clientData) {
      this.setState({ clientData: formData.clientData });
    }
  };

  render() {
    const { clientData } = this.state;
    const { currentLang, formData, isActive } = this.props;

    const formItems = [
      {
        name: "firstName",
        required: true,
        label: strings[currentLang].nat.FIRST_NAME,
        onChange: this.onValChange,
        defaultValue: formData.clientData
          ? formData.clientData.firstName
          : null,
      },
      {
        name: "lastName",
        required: true,
        label: strings[currentLang].nat.LAST_NAME,
        maxLength: 24,
        onChange: this.onValChange,
        defaultValue: formData.clientData ? formData.clientData.lastName : null,
      },
      {
        name: "dateOfBirth",
        required: true,
        onChange: this.onValChange,
        label: strings[currentLang].nat.DATE_OF_BIRTH,
        type: "birthday",
        defaultValue: formData.clientData
          ? formData.clientData.dateOfBirth
          : null,
      },
      {
        name: "nationality",
        required: true,
        onChange: this.onValChange,
        label: strings[currentLang].nat.NATIONALITY,
        type: "country",
        defaultValue: formData.clientData
          ? formData.clientData.nationality
          : null,
      },
      {
        name: "street",
        required: true,
        onChange: this.onValChange,
        label: `${strings[currentLang].nat.STREET}`,
        validationFunc: (val) => {
          const x = val.trim();
          return x.indexOf(" ") > 0;
        },
        errorMsg: strings[currentLang].ERROR_MSG_ADDRESS,
        defaultValue: formData.clientData ? formData.clientData.street : null,
      },
      {
        name: "postalCode",
        required: true,
        label: strings[currentLang].nat.POSTAL_CODE,
        onChange: this.onValChange,
        defaultValue: formData.clientData
          ? formData.clientData.postalCode
          : null,
        // validationFunc: (val) => /^\d+$/.test(val),
      },
      {
        name: "city",
        required: true,
        label: strings[currentLang].nat.CITY,
        onChange: this.onValChange,
        validationFunc: (val) => /^[a-zA-Z ]+$/.test(val),
        errorMsg: strings[currentLang].ERROR_MSG_ONLY_LETTERS_ALLOWED,
        defaultValue: formData.clientData ? formData.clientData.city : null,
      },
      {
        name: "country",
        required: true,
        label: strings[currentLang].nat.COUNTRY,
        onChange: this.onValChange,
        type: "country",
        defaultValue: formData.clientData ? formData.clientData.country : null,
      },
      {
        name: "invoiceEmail",
        required: true,
        label: strings[currentLang].nat.EMAIL_ADDRESS_FOR_FEE_NOTES,
        onChange: this.onValChange,
        type: "email",
        // help: strings[currentLang].HINT_EMAIL_ADDRESS_FOR_INVOICES,
        hint: strings[currentLang].HINT_EMAIL_ADDRESS_FOR_INVOICES_DESC,
        defaultValue: formData.clientData
          ? formData.clientData.invoiceEmail
          : null,
      },
      {
        name: "phone",
        required: true,
        label: strings[currentLang].nat.PHONE_NUMBER,
        type: "phone",
        onChange: this.onValChange,
        defaultValue: formData.clientData ? formData.clientData.phone : null,
      },
      {
        name: "vatNumber",
        label: strings[currentLang].nat.VAT_NUMBER_FOR_COMPANY,
        onChange: this.onValChange,
        type: "vat",
        countryCode: (clientData && clientData.country) || null,
        defaultValue: formData.clientData
          ? formData.clientData.vatNumber
          : null,
      },
    ];

    return (
      <>
        <h2>{strings[currentLang].nat.STEP_CLIENT_INFORMATION}</h2>
        <CustomForm
          isActive={isActive}
          currentLang={currentLang}
          formItems={formItems}
          onValid={() => {
            this.setState({ allFieldsValid: true }, this.validate);
          }}
          onInvalid={() => {
            this.setState({ allFieldsValid: false }, this.validate);
          }}
        />
      </>
    );
  }
}

NatStammdaten.propTypes = {
  isActive: PropTypes.bool,
  currentLang: PropTypes.oneOf(["de", "en"]),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  formData: PropTypes.any,
};

export default NatStammdaten;
