import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Checkbox, Descriptions, Input, Space } from "antd";
import ReactJson from "react-json-view";
import strings from "../../locale/strings.json";
import moment from "moment";
import countries from "i18n-iso-countries";

class NatZusammenfassung extends Component {
  state = { confirmed: false, additionalInformation: null };

  constructor(props) {
    super(props);

    this.textareaChangeTimeout = null;
  }

  validate = () => {
    const { confirmed } = this.state;
    const { setCurrentStepValid } = this.props;

    setCurrentStepValid(confirmed);
  };

  componentDidUpdate = () => {
    const { isActive, formData } = this.props;

    const { additionalInformation } = this.state;

    if (isActive) {
      if (additionalInformation == null && formData.additionalInformation) {
        this.setState({
          additionalInformation: formData.additionalInformation,
        });
      }
    }
  };

  render() {
    const { confirmed, additionalInformation } = this.state;
    const { currentLang, formData, setCurrentStepValid, onChangeFormData } =
      this.props;

    return (
      <>
        <Space style={{ width: "100%" }} direction="vertical" size="large">
          <div>
            <h3>{strings[currentLang].PLEASE_CHECK_SUMMARY_OF_YOUR_DATA}</h3>
            <Descriptions
              column={1}
              bordered
              size="small"
              labelStyle={{
                verticalAlign: "top",
                fontWeight: "bold",
                width: "30%",
              }}
            >
              {formData.clientType === "nat" && formData.clientData && (
                <>
                  <Descriptions.Item
                    label={strings[currentLang].nat.FIRST_AND_LAST_NAME}
                  >
                    {formData.clientData.firstName}{" "}
                    {formData.clientData.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.DATE_OF_BIRTH}
                  >
                    {moment(formData.clientData.dateOfBirth).format(
                      "DD.MM.YYYY"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.NATIONALITY}
                  >
                    {countries.getName(
                      formData.clientData.nationality,
                      currentLang,
                      { select: "official" }
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={strings[currentLang].nat.STREET}>
                    {formData.clientData.street}
                    <br />
                    {formData.clientData.postalCode} {formData.clientData.city}
                    <br />
                    {countries.getName(
                      formData.clientData.country,
                      currentLang,
                      { select: "official" }
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.PHONE_NUMBER}
                  >
                    +{formData.clientData.phone}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.EMAIL_ADDRESS}
                  >
                    {formData.clientData.invoiceEmail}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.VAT_NUMBER}
                  >
                    {formData.clientData.vatNumber
                      ? `${formData.clientData.country} ${formData.clientData.vatNumber}`
                      : "N/A"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.STEP_TITLE_TAXATION}
                  >
                    {countries.getName(
                      formData.clientSubjectToTaxationInCountry,
                      currentLang,
                      { select: "official" }
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.STEP_TITLE_LEGAL_SERVICES}
                  >
                    {formData.legalServices &&
                      formData.legalServices.map((ls) => <p key={ls}>{ls}</p>)}
                    {formData.otherLegalService && (
                      <p>
                        {strings[currentLang].nat.OTHER}:{" "}
                        {formData.otherLegalService}
                      </p>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={strings[currentLang].nat.STEP_TITLE_PEP}
                  >
                    {formData.pep}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </div>

          <Input.TextArea
            placeholder={strings[
              currentLang
            ].nat.STEP_ADDITIONAL_REMARKS.replace(":", "")}
            rows={2}
            value={additionalInformation}
            onChange={(e) => {
              this.setState({ additionalInformation: e.target.value });
              if (this.textareaChangeTimeout)
                clearTimeout(this.textareaChangeTimeout);

              this.textareaChangeTimeout = setTimeout(() => {
                onChangeFormData("additionalInformation", e.target.value);
              }, 400);
            }}
          />

          <Alert
            type="warning"
            description={
              <Checkbox
                checked={confirmed}
                onChange={(e) => {
                  this.setState({ confirmed: e.target.checked }, this.validate);
                }}
              >
                {strings[currentLang].nat.I_CONFIRM_PROVIDED_INFORMATION}
              </Checkbox>
            }
          />
        </Space>
      </>
    );
  }
}

NatZusammenfassung.propTypes = {
  isActive: PropTypes.bool,
  currentLang: PropTypes.any,
  formData: PropTypes.any,
  setCurrentStepValid: PropTypes.func,
  onChangeFormData: PropTypes.func,
};

export default NatZusammenfassung;
