import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Input, Modal, Radio, Space } from "antd";
import ReactMarkdown from "react-markdown";
import strings from "../../locale/strings.json";
import pepDefinitionMd from "../../locale/PEP_en.md";
import pepDefinitionMdDE from "../../locale/PEP_de.md";

class JurPEP extends Component {
  state = {
    selectedAnswer1: null,
    selectedAnswer2: null,
    explanation1: null,
    explanation2: null,
    pepDefinition: null,
  };

  constructor(props) {
    super(props);
    const { currentLang } = props;

    fetch(currentLang === "de" ? pepDefinitionMdDE : pepDefinitionMd)
      .then((res) => res.text())
      .then((text) => {
        this.state.pepDefinition = text;
      });

    this.textareaChangedTimeout = null;
  }

  validate = () => {
    const { selectedAnswer1, selectedAnswer2 } = this.state;
    const { formData, setCurrentStepValid, isActive, currentLang } = this.props;

    const {
      managingDirectorsPepExplanation1,
      managingDirectorsPepExplanation2,
    } = formData;

    if (isActive) {
      if (selectedAnswer1 && selectedAnswer1 === strings[currentLang].NO) {
        if (selectedAnswer2 && selectedAnswer2 === strings[currentLang].NO) {
          setCurrentStepValid(true);
        } else if (
          selectedAnswer2 &&
          selectedAnswer2 === strings[currentLang].YES &&
          managingDirectorsPepExplanation2 &&
          managingDirectorsPepExplanation2.length > 2
        ) {
          setCurrentStepValid(true);
        } else {
          setCurrentStepValid(false);
        }
      } else if (
        selectedAnswer1 &&
        selectedAnswer1 === strings[currentLang].YES &&
        managingDirectorsPepExplanation1 &&
        managingDirectorsPepExplanation1.length > 2
      ) {
        if (selectedAnswer2 && selectedAnswer2 === strings[currentLang].NO) {
          setCurrentStepValid(true);
        } else if (
          selectedAnswer2 &&
          selectedAnswer2 === strings[currentLang].YES &&
          managingDirectorsPepExplanation2 &&
          managingDirectorsPepExplanation2.length > 2
        ) {
          setCurrentStepValid(true);
        } else {
          setCurrentStepValid(false);
        }
      } else {
        setCurrentStepValid(false);
      }
    }
  };

  componentDidUpdate = () => {
    const { isActive, formData } = this.props;
    const { selectedAnswer1, selectedAnswer2 } = this.state;

    if (isActive) {
      if (selectedAnswer1 == null && formData.managingDirectorsPep != null) {
        this.setState(
          {
            selectedAnswer1: formData.managingDirectorsPep,
            explanation1: formData.managingDirectorsPepExplanation1,
          },
          this.validate
        );
      }

      if (
        selectedAnswer2 == null &&
        formData.managingDirectorsFamilyMemberOrCloseAssociatePep != null
      ) {
        this.setState(
          {
            selectedAnswer2:
              formData.managingDirectorsFamilyMemberOrCloseAssociatePep,
            explanation2: formData.managingDirectorsPepExplanation2,
          },
          this.validate
        );
      }
    }
  };

  render() {
    const {
      selectedAnswer1,
      selectedAnswer2,
      pepDefinition,
      explanation1,
      explanation2,
    } = this.state;
    const { currentLang, onChangeFormData } = this.props;

    return (
      <>
        <h2 style={{ marginBottom: 0, padding: 0 }}>
          {strings[currentLang].jur.STEP_PEP}
        </h2>
        <div style={{ textAlign: "left" }}>
          <Button
            size="small"
            type="link"
            style={{ margin: 0, padding: 0 }}
            onClick={() => {
              Modal.info({
                title: strings[currentLang].MODAL_TITLE_PEP_DEFINITION,
                content: <ReactMarkdown>{pepDefinition}</ReactMarkdown>,
                width: "80%",
                centered: true,
              });
            }}
          >
            {strings[currentLang].WHAT_IS_A_PEP}
          </Button>
        </div>

        <Radio.Group
          value={selectedAnswer1}
          onChange={(e) => {
            this.setState({ selectedAnswer1: e.target.value }, this.validate);
            onChangeFormData("managingDirectorsPep", e.target.value);

            if (e.target.value === strings[currentLang].NO) {
              onChangeFormData("managingDirectorsPepExplanation1", null);
            }
          }}
        >
          <Space direction="vertical">
            <Radio value={strings[currentLang].YES}>
              {strings[currentLang].YES}
            </Radio>
            <Radio value={strings[currentLang].NO}>
              {strings[currentLang].NO}
            </Radio>
          </Space>
        </Radio.Group>

        <div
          style={{ marginTop: "24px" }}
          className={
            selectedAnswer1 != null &&
            selectedAnswer1 === strings[currentLang].YES
              ? "fade-in"
              : "fade-out"
          }
        >
          <h3>{strings[currentLang].PLEASE_EXPLAIN}</h3>
          <Input.TextArea
            rows={3}
            style={{ resize: "none" }}
            value={explanation1}
            onChange={(e) => {
              this.setState({ explanation1: e.target.value });
              if (this.textareaChangedTimeout) clearTimeout(this.text);
              this.textareaChangedTimeout = setTimeout(() => {
                onChangeFormData(
                  "managingDirectorsPepExplanation1",
                  e.target.value
                );
                this.validate();
              }, 400);
            }}
          />
        </div>

        <h2 style={{ marginBottom: 0, padding: 0 }}>
          {strings[currentLang].jur.STEP_PEP2}
        </h2>

        <Radio.Group
          value={selectedAnswer2}
          onChange={(e) => {
            this.setState({ selectedAnswer2: e.target.value }, this.validate);
            onChangeFormData(
              "managingDirectorsFamilyMemberOrCloseAssociatePep",
              e.target.value
            );

            if (e.target.value === strings[currentLang].NO) {
              onChangeFormData("managingDirectorsPepExplanation2", null);
            }
          }}
        >
          <Space direction="vertical">
            <Radio value={strings[currentLang].YES}>
              {strings[currentLang].YES}
            </Radio>
            <Radio value={strings[currentLang].NO}>
              {strings[currentLang].NO}
            </Radio>
          </Space>
        </Radio.Group>

        <div
          style={{ marginTop: "24px" }}
          className={
            selectedAnswer2 != null &&
            selectedAnswer2 === strings[currentLang].YES
              ? "fade-in"
              : "fade-out"
          }
        >
          <h3>{strings[currentLang].PLEASE_EXPLAIN}</h3>
          <Input.TextArea
            rows={3}
            style={{ resize: "none" }}
            value={explanation2}
            onChange={(e) => {
              this.setState({ explanation2: e.target.value });
              if (this.textareaChangedTimeout) clearTimeout(this.text);
              this.textareaChangedTimeout = setTimeout(() => {
                onChangeFormData(
                  "managingDirectorsPepExplanation2",
                  e.target.value
                );
                this.validate();
              }, 400);
            }}
          />
        </div>
      </>
    );
  }
}

JurPEP.propTypes = {
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    managingDirectorsPep: PropTypes.string,
    managingDirectorsFamilyMemberOrCloseAssociatePep: PropTypes.string,
    managingDirectorsPepExplanation1: PropTypes.any,
    managingDirectorsPepExplanation2: PropTypes.any,
  }),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
};

export default JurPEP;
