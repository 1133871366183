import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Result } from "antd";
import strings from "../locale/strings.json";
import successAnimation from "../animations/success_animation.json";
import { useLottie } from "lottie-react";

const StepFormSubmitted = ({ currentLang, hideSteps }) => {
  const { View } = useLottie({
    animationData: successAnimation,
    loop: false,
    style: { width: "240px", maxWidth: "60%" },
  });

  useEffect(() => {
    hideSteps();
  }, []);

  return (
    <Result
      icon={View}
      status="success"
      title={strings[currentLang].STEP_FORM_SUBMITTED_TITLE}
      subTitle={strings[currentLang].STEP_FORM_SUBMITTED_SUBTITLE}
    />
  );
};

StepFormSubmitted.propTypes = {
  currentLang: PropTypes.string,
  hideSteps: PropTypes.func,
};

export default StepFormSubmitted;
