import React from "react";
import PropTypes from "prop-types";
import { Button, Row } from "antd";
import strings from "../../../locale/strings.json";
import CustomForm from "../../../util/CustomForm";

const JurKontaktPersonCard = ({
  currentLang,
  onRemove,
  onChange,
  onValid,
  onInvalid,
  defaultData,
  readOnly,
}) => {
  const formItems = [
    {
      name: "firstName",
      required: true,
      label: strings[currentLang].jur.FIRST_NAME,
      onChange: onChange,
      defaultValue: defaultData.firstName,
      readOnly,
    },
    {
      name: "lastName",
      required: true,
      label: strings[currentLang].jur.LAST_NAME,
      onChange: onChange,
      defaultValue: defaultData.lastName,
      readOnly,
    },
    {
      name: "email",
      required: true,
      onChange: onChange,
      label: strings[currentLang].jur.EMAIL_ADDRESS,
      type: "email",
      defaultValue: defaultData.email,
      readOnly,
    },
    {
      name: "phone",
      required: true,
      onChange: onChange,
      label: strings[currentLang].jur.PHONE_NUMBER,
      type: "phone",
      help: strings[currentLang].HINT_PHONE_NUMBER,
      defaultValue: defaultData.phone,
      readOnly,
    },
  ];

  return (
    <>
      <Row justify="end">
        <Button type="link" danger onClick={onRemove}>
          Remove
        </Button>
      </Row>
      <CustomForm
        formItems={formItems}
        onValid={onValid}
        onInvalid={onInvalid}
      />
    </>
  );
};

JurKontaktPersonCard.propTypes = {
  currentLang: PropTypes.any,
  onChange: PropTypes.func,
  onValid: PropTypes.func,
  onInvalid: PropTypes.func,
  onRemove: PropTypes.any,
  defaultData: PropTypes.any,
  readOnly: PropTypes.bool,
};

export default JurKontaktPersonCard;
