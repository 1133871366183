import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, message, Row } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PinInput from "react-pin-input";
import { useAuth } from "./AuthProvider";
import strings from "./locale/strings.json";

const Authenticate = ({ currentLang }) => {
  const { formId } = useParams();

  const [loading, setLoading] = useState(false);
  const [pincode, setPincode] = useState("");

  const { tryPin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  let inputRef = null;

  const handleFetchToken = async (p) => {
    setLoading(true);
    const success = await tryPin(formId, p);
    setLoading(false);

    if (success) {
      if (
        location &&
        location.state &&
        location.state.from &&
        location.state.from.pathname
      ) {
        // redirect zur passenden seite, wo man eigentlich hin wollte
        navigate(location.state.from.pathname);
      } else {
        navigate(`/form/${formId}`);
      }
    } else {
      message.error("Please provide the correct PIN code.");
    }
  };

  return (
    <>
      <h2>{strings[currentLang].PLEASE_ENTER_PIN}</h2>
      <Row gutter={[6, 24]} style={{ marginBottom: "24px" }}>
        <Col
          xs={24}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PinInput
            focus
            length={6}
            initialValue=""
            onChange={(val) => {
              setPincode(val);
            }}
            disabled={loading}
            inputMode="number"
            type="numeric"
            onComplete={(val) => {
              setPincode(val);
              handleFetchToken(val);
            }}
            inputStyle={{
              borderRadius: "12px",
              border: "2px solid #999999",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              fontSize: "1.4rem",
              fontWeight: "bold",
              fontFamily: "Calibri, sans-serif",
              backgroundColor: "#ffffff",
              width: "40px",
            }}
            style={{ display: "flex", justifyContent: "center" }}
            inputFocusStyle={{
              borderRadius: "12px",
              border: "2px solid #11a2f0",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              fontSize: "1.4rem",
              fontWeight: "bold",
              fontFamily: "Calibri, sans-serif",
              backgroundColor: "#ffffff",
            }}
            ref={(n) => {
              if (n) inputRef = n;
            }}
          />
        </Col>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <Button
            type="link"
            size="small"
            onClick={() => {
              setPincode("");
              inputRef.clear();
            }}
            disabled={!pincode || pincode === ""}
          >
            Clear
          </Button>
          <Button
            loading={loading}
            type="primary"
            shape="circle"
            icon={<ArrowRightOutlined />}
            disabled={!pincode || pincode.length < 6}
            onClick={() => {
              handleFetchToken(pincode);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

Authenticate.propTypes = {
  currentLang: PropTypes.string,
};

export default Authenticate;
