import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Card, Checkbox, Col, Row } from "antd";
import { v4 as uuid } from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import strings from "../../../locale/strings.json";
import JurKontaktPersonCard from "./JurKontaktPersonCard";
import _ from "lodash";

class JurKontaktperson extends Component {
  state = { selectedPersons: [] };

  validate = () => {
    const { selectedPersons } = this.state;
    const { formData, setCurrentStepValid } = this.props;
    const { contactData } = formData;

    const allContactPersonsValid =
      contactData && contactData.length > 0
        ? contactData.filter((cd) => !cd.valid).length === 0
        : false;

    if (
      contactData &&
      contactData.length > 0 &&
      allContactPersonsValid &&
      selectedPersons.length > 0
    ) {
      setCurrentStepValid(true);
    } else if (
      selectedPersons.length > 0 &&
      (!contactData || contactData.length === 0)
    ) {
      setCurrentStepValid(true);
    } else if (
      selectedPersons.length === 0 &&
      contactData &&
      contactData.length > 0 &&
      allContactPersonsValid
    ) {
      setCurrentStepValid(true);
    } else {
      setCurrentStepValid(false);
    }
  };

  handleAddNewContact = () => {
    const { formData, onChangeFormData } = this.props;
    const { contactData } = formData;

    if (!contactData) {
      onChangeFormData("contactData", [{ key: uuid(), valid: false }]);
    } else {
      onChangeFormData("contactData", [
        ...contactData,
        { key: uuid(), valid: false },
      ]);
    }
  };

  handleRemoveContact = (index) => {
    const { formData, onChangeFormData } = this.props;
    const { contactData } = formData;

    if (contactData) {
      const p = contactData[index];

      if (p) {
        const { selectedPersons } = this.state;
        this.setState(
          {
            selectedPersons: selectedPersons.filter((sp) => sp.key !== p.key),
          },
          this.validate
        );

        contactData.splice(index, 1, 1);
      }

      onChangeFormData(
        "contactData",
        contactData.filter((cd, i) => i !== index)
      );
    }

    this.validate();
  };

  handleContactDataChange = (index, key, value) => {
    const { formData, onChangeFormData } = this.props;
    const { contactData } = formData;

    if (contactData) {
      contactData[index][key] = value;
      onChangeFormData("contactData", contactData);
    }

    this.validate();
  };

  insertNameIntoHeader = (nameLegalEntity, header) =>
    header.replace("[NAME_LEGAL_ENTITY]", nameLegalEntity);

  componentDidUpdate = (prevProps) => {
    const { formData, isActive } = this.props;
    const { formData: prevFormData, isActive: wasActive } = prevProps;

    if (
      formData &&
      !_.isEqual(prevFormData.contactData, formData.contactData)
    ) {
      this.validate();
    }

    if (isActive && !wasActive) {
      const {
        formData: { contactData },
      } = this.props;
      this.setState(
        {
          selectedPersons:
            contactData && Array.isArray(contactData)
              ? contactData.filter((cd) => cd.key != null)
              : [],
        },
        this.validate
      );
    }
  };

  render() {
    const { selectedPersons } = this.state;

    const { currentLang, formData } = this.props;

    const { contactData } = formData;

    let managingDirectors = [];
    let beneficialOwners = [];

    if (formData.managingDirectors)
      managingDirectors = formData.managingDirectors;
    if (formData.beneficialOwners) beneficialOwners = formData.beneficialOwners;

    const allContactPersonsValid =
      contactData && contactData.length > 0
        ? contactData.filter((cd) => !cd.valid).length === 0
        : false;

    return (
      <>
        <h2>
          {this.insertNameIntoHeader(
            formData.clientData ? formData.clientData.nameLegalEntity : "",
            strings[currentLang].jur.STEP_CONTACT_PERSON
          )}
        </h2>
        <Row>
          <Col xs={12}>
            {managingDirectors && (
              <>
                {managingDirectors.map((md) => (
                  <Checkbox
                    key={md.key}
                    checked={
                      selectedPersons.findIndex((sp) => sp.key === md.key) >= 0
                    }
                    onChange={(e) => {
                      const { checked } = e.target;
                      const { formData, onChangeFormData } = this.props;
                      const { contactData } = formData;

                      const { selectedPersons } = this.state;

                      if (checked) {
                        const person = {
                          key: md.key,
                          firstName: md.firstName,
                          lastName: md.lastName,
                          email: md.email,
                          phone: md.phone,
                        };
                        onChangeFormData(
                          "contactData",
                          _.isEmpty(contactData)
                            ? [person]
                            : [...contactData, person]
                        );
                        this.setState(
                          {
                            selectedPersons: [...selectedPersons, person],
                          },
                          this.validate
                        );
                      } else {
                        onChangeFormData(
                          "contactData",
                          contactData.filter((c) => c.key !== md.key)
                        );
                        this.setState(
                          {
                            selectedPersons: selectedPersons.filter(
                              (sp) => sp.key !== md.key
                            ),
                          },
                          this.validate
                        );
                      }
                    }}
                  >
                    {md.firstName} {md.lastName}
                  </Checkbox>
                ))}
              </>
            )}
          </Col>
          <Col xs={12}>
            <Button
              type="default"
              size="small"
              icon={<PlusOutlined />}
              onClick={this.handleAddNewContact}
              disabled={
                !allContactPersonsValid && contactData && contactData.length > 0
              }
            >
              {strings[currentLang].ADD_NEW_POINT_OF_CONTACT}
            </Button>
          </Col>
        </Row>

        {contactData && contactData.length > 0 ? (
          <Row gutter={[12, 12]} style={{ marginTop: "24px" }}>
            {contactData.map((cd, index) => (
              <Col xs={24} key={cd.key}>
                <Card>
                  <JurKontaktPersonCard
                    defaultData={cd}
                    currentLang={currentLang}
                    onRemove={() => {
                      this.handleRemoveContact(index);
                    }}
                    onChange={(key, value) => {
                      this.handleContactDataChange(index, key, value);
                    }}
                    onValid={() => {
                      this.handleContactDataChange(index, "valid", true);
                    }}
                    onInvalid={() => {
                      this.handleContactDataChange(index, "valid", false);
                    }}
                    readOnly={
                      beneficialOwners.findIndex((bo) => bo.key === cd.key) >= 0
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <></>
        )}
      </>
    );
  }
}

JurKontaktperson.propTypes = {
  currentLang: PropTypes.any,
  formData: PropTypes.shape({
    beneficialOwners: PropTypes.any,
    clientData: PropTypes.shape({
      nameLegalEntity: PropTypes.any,
    }),
    contactData: PropTypes.arrayOf(
      PropTypes.shape({
        filter: PropTypes.func,
        length: PropTypes.number,
        map: PropTypes.func,
        key: PropTypes.string,
      })
    ),
    managingDirectors: PropTypes.any,
  }),
  onChangeFormData: PropTypes.func,
  setCurrentStepValid: PropTypes.func,
  isActive: PropTypes.bool,
};

export default JurKontaktperson;
