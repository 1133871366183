import React, { useEffect, useState } from "react";
import { Result, Spin } from "antd";
import PropTypes from "prop-types";
import strings from "../locale/strings.json";
import { useLottie } from "lottie-react";
import successAnimation from "../animations/success_animation.json";
import { confirmForm } from "../api/api";
import { useParams } from "react-router-dom";

const StepFormConfirmed = ({ currentLang }) => {
  const params = useParams();

  const { View } = useLottie({
    animationData: successAnimation,
    loop: false,
    style: { width: "240px", maxWidth: "60%" },
  });

  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function f() {
      setLoading(true);
      const { success: s, error: err } = (await confirmForm(params.formId))
        .data;

      if (s) {
        setSuccess(true);
      } else {
        setError(err);
      }
      setLoading(false);
    }
    f();
  }, []);

  if (loading) return <Spin spinning />;

  if (success)
    return (
      <Result
        icon={View}
        status="success"
        title={strings[currentLang].STEP_FORM_CONFIRMED_TITLE}
        subTitle={strings[currentLang].STEP_FORM_CONFIRMED_SUBTITLE}
      />
    );

  return (
    <Result
      status="error"
      title={strings[currentLang].ERROR}
      subTitle={strings[currentLang].ERROR_FORM_ALREADY_CONFIRMED}
    />
  );
};

StepFormConfirmed.propTypes = {
  currentLang: PropTypes.string,
};

export default StepFormConfirmed;
